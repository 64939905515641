import React from "react";
import { Col, Row } from "react-bootstrap";

const DeviceStat = ({ data }) => {
  return (
    <Col md="4">
      <Row>
        <Col className="text-center mb-4">
          <h5>{data.label}</h5>
          <p>{data.value}</p>
        </Col>
      </Row>
    </Col>
  );
};

export default DeviceStat;

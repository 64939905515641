import React from "react";
import "./Marker.css";

const Marker = (props) => {
  const { colour, name } = props;
  return (
    <div
      className="pin bounce"
      style={{ backgroundColor: colour, cursor: "pointer" }}
      title={name}
    />
  );
};

export default Marker;

import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row, Breadcrumb } from "react-bootstrap";

import GetSupport from "../../components/GetSupport";
import OffcanvasHelp from "../../components/OffcanvasHelp";
import CustomerRegistrationsList from "./CustomerRegistrationsList";

import { useNavigate } from "react-router-dom";

const ViewCustomerRegistrations = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet title="Customer Registrations" />
      <Container fluid className="p-0">
        <Breadcrumb className="float-end mt-0">
          <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Customer Registrations</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          Customer Registrations
          <OffcanvasHelp id="customers" name="Customers" scroll backdrop />
        </h1>
        <div className="clearfix"></div>
        <Row>
          <Col xl="9">
            <CustomerRegistrationsList />
          </Col>
          <Col xl="3">
            <GetSupport type="commercial" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ViewCustomerRegistrations;

import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { apiConfig } from "../../config";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import NotyfContext from "../../contexts/NotyfContext";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import GetSupport from "../../components/GetSupport";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";

import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Breadcrumb,
  Modal,
  Badge,
} from "react-bootstrap";
import Select from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

import WifiStats from "./WifiStats";

import useAuth from "../../hooks/useAuth";

import { setRefreshData } from "../../redux/slices/refreshData";

const WifiNetworksList = () => {
  const [passedData, setPassedData] = useState({});
  const passData = (data) => {
    setPassedData(data);
  };
  // Modal config for "Configure Wireless Network" Action
  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());

  const statusOptions = [
    { value: "AlwaysOn", label: "Always Online" },
    { value: "AlwaysOff", label: "Always Offline" },
    //{ value: "Customized", label: "Scheduled" },
  ];

  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };
  const statusColourMap = {
    Offline: "danger",
    Online: "success",
    Scheduled: "warning",
  };
  const wifiNetworksColumns = [
    {
      Header: "SSID",
      accessor: "wlan_ssid",
      filter: "includes",
    },
    {
      Header: "Building",
      accessor: "building_id",
      filter: "includes",
    },
    {
      Header: "Auth Type",
      accessor: "wlan_auth_type",
      filter: "includes",
    },
    {
      Header: "Status",
      accessor: "wlan_status",
      Cell: (e) => <Badge bg={statusColourMap[e.value]}>{e.value}</Badge>,
      filter: "includes",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <>
          <IsAllowed to="edit:wifi">
            <FontAwesomeIcon
              icon={faCog}
              className="ms-2"
              onClick={() => {
                toggle("configureWirelessNetwork");
                passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          </IsAllowed>
        </>
      ),
    },
  ];

  // Update WLAN Status Form State
  const [wlanStatus, setWlanStatus] = useState("");
  const handleUpdateStatus = (event) => {
    event.preventDefault();
    const updateStatusBody = {
      zoneGuid: passedData.wlan_zoneGuid,
      wlanGuid: passedData.wlan_wlanGuid,
      status: wlanStatus,
    };
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(
        apiConfig.ruckusApiUrl + "wlans/status",
        updateStatusBody,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          notyf.open({
            type,
            message: "WLAN status has been successfully updated",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((error) => {
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        console.log(error);
      });
  };

  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const [wlansList, setWlansList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const refreshData = useSelector((state) => state.refreshdata);
  React.useEffect(() => {
    let isMounted = true;
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    let wlansApi =
      apiConfig.ruckusApiUrl +
      "wlans?operatorid=" +
      selectedLandlord.landlordid;
    let buildingsApi =
      apiConfig.connectedApiUrl +
      "buildings?building_operator=" +
      selectedLandlord.landlordid +
      "&page_size=1000";
    const wlansRequest = axios.get(wlansApi, axiosConfig);
    const buildingsRequest = axios.get(buildingsApi, axiosConfig);
    axios
      .all([wlansRequest, buildingsRequest])
      .then(
        axios.spread((...responses) => {
          const wlansResponse = responses[0];
          const buildingsResponse = responses[1];
          let wlansList = [];
          wlansResponse.data.forEach((wlan) => {
            let buildingMatch = buildingsResponse.data.buildings.items.filter(
              (building) => {
                return building.id === wlan?.building_id;
              }
            );
            wlansList.push({
              wlan_ssid: wlan.wlan_ssid,
              building_id: buildingMatch[0]?.building_name,
              wlan_auth_type: wlan.wlan_auth_type,
              wlan_status: wlan.wlan_status,
              wlan_zoneGuid: wlan.zone_guid,
              wlan_wlanGuid: wlan.wlan_guid,
            });
          });
          if (isMounted) {
            //setLoading(false);
            setWlansList(wlansList);
            setLoading(false);
          }
        })
      )
      .catch(function (error) {
        // handle error
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
    return () => {
      isMounted = false;
    };
  }, [refreshData, bearerToken, selectedLandlord.landlordid]);
  const dispatch = useDispatch();
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title className="mb-0">Wireless Networks List</Card.Title>
        </Card.Header>
        <Card.Body className="py-0">
          {loading && (
            <>
              <LogoLoader className="d-block m-auto p-5" />
            </>
          )}
          {!loading && noData && <NotFound />}
          {!loading && !noData && (
            <ColumnFilteringTable
              columns={wifiNetworksColumns}
              data={wlansList}
              actions
            />
          )}
        </Card.Body>
      </Card>

      <Modal
        show={openModals["configureWirelessNetwork"]}
        onHide={() => toggle("configureWirelessNetwork")}
        centered
      >
        <Form onSubmit={handleUpdateStatus}>
          <Modal.Header closeButton>
            <b>Admin Function:</b>&nbsp; Configure Wireless Network
          </Modal.Header>
          <Modal.Body className="m-3">
            <p className="text-left my-0">
              The <b>{passedData.wlan_ssid}</b> network at{" "}
              <b>{passedData.building_id}</b> is currently{" "}
              <Badge bg="success">{passedData.wlan_status}</Badge>.
              <br />
              <br />
              Change the status of the wireless network by selecting a new
              status from the list below. Note that this will have an immediate
              impact on the wireless network configuration.
              <br />
              <>
                <Select
                  className="react-select-container mt-2"
                  classNamePrefix="react-select"
                  options={statusOptions}
                  onChange={(e) => setWlanStatus(e.value)}
                />
              </>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggle("configureWirelessNetwork")}
            >
              Close
            </Button>{" "}
            <Button
              variant="info"
              type="submit"
              onClick={() => {
                toggle("configureWirelessNetwork");
                setTimeout(() => {
                  dispatch(setRefreshData());
                }, 2000);
                /*notyf.open({
                  type,
                  message: "The wireless network configuration has been updated.",
                  duration,
                  ripple,
                  dismissible,
                  position: {
                    x: positionX,
                    y: positionY,
                  },
                });*/
              }}
            >
              Update Wireless Network
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const WirelessNetworksList = () => {
  const navigate = useNavigate();
  return (
    <IsAllowed to="view:wifi" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Wireless Networks" />
        <Container fluid className="p-0">
          <Breadcrumb className="float-end mt-2">
            <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Wireless Networks</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h3 mb-3">
            Wireless Networks
            <OffcanvasHelp id="wifi" name="Wireless Networks" scroll backdrop />
          </h1>
          <div className="clearfix"></div>
          <Row>
            <WifiStats />
          </Row>
          <Row>
            <Col xl="9">
              <WifiNetworksList />
            </Col>
            <Col xl="3">
              <GetSupport type="wifi" />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </IsAllowed>
  );
};

export default WirelessNetworksList;

import { useMemo } from "react";
import useAuth from "./useAuth";
import { apiConfig } from "../config";

const useAxiosConfig = (apiName) => {
  const { userAccessToken } = useAuth();

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${userAccessToken}`,
    }),
    [userAccessToken]
  );

  if (!apiConfig[apiName]) {
    throw new Error(`API configuration for ${apiName} not found.`);
  }

  const baseURL = apiConfig[apiName];

  return { baseURL, headers };
};

export default useAxiosConfig;

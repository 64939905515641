import { apiConfig } from "../config";
import axios from "axios";

const notificationConfig = {
  type: "success",
  duration: "30000",
  ripple: true,
  dismissible: true,
  positionX: "right",
  positionY: "top",
};

const { type, duration, ripple, dismissible, positionX, positionY } =
  notificationConfig;

// Helper functions for downloading invoices
function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

function saveByteArray(reportName, byte) {
  const blob = new Blob([byte], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = reportName;
  link.click();
}

const downloadInvoice = async (
  accountnumber,
  invoiceperiod,
  bearerToken,
  notyf
) => {
  const axiosConfig = {
    headers: { Authorization: bearerToken },
  };
  axios
    .get(
      apiConfig.symbillApiUrl +
        "billing/invoices/" +
        accountnumber +
        "/" +
        invoiceperiod,
      axiosConfig
    )
    .then((response) => {
      if (response.status === 200) {
        notyf.open({
          type,
          message: "Invoice has been downloaded",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      } else {
        notyf.open({
          type: "warning",
          message: response.response.data,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
      }
      let pdfArr = base64ToArrayBuffer(response.data.FileBytes);
      saveByteArray(response.data.Title + ".pdf", pdfArr);
    });
};

export { base64ToArrayBuffer, saveByteArray, downloadInvoice };

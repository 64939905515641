import React, { useState } from "react";

import { Badge, Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import BandwidthAmount from "./BandwidthAmount";

const BuildingNetworkInfo = (data) => {
  const [visibileCircuits, setVisibileCircuits] = useState({});

  const toggleVisibility = (index) => {
    setVisibileCircuits((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <Card className="flex-fill">
      <Card.Header>
        <Card.Title className="mb-0">Building Network Details</Card.Title>
      </Card.Header>
      <Card.Body className="py-0">
        <Table size="">
          <tbody>
            <tr>
              <th>Building Name</th>
              <td>{data.building_name}</td>
            </tr>
            <tr>
              <th>Technical Reference</th>
              <td>{data.data.building_code}</td>
            </tr>
            <tr>
              <th>Total Bandwidth Available</th>
              <td>
                <Badge bg="danger" className="btn-gradient">
                  <BandwidthAmount bandwidth={data.data.total_bw} />
                </Badge>
              </td>
            </tr>
            <tr>
              <th>OSIA Allocated</th>
              <td>
                <Badge bg="danger" className="btn-gradient">
                  <BandwidthAmount bandwidth={data.data.osia_bw} />
                </Badge>
              </td>
            </tr>
            <tr>
              <th>DIA Allocated</th>
              <td>
                <Badge bg="danger" className="btn-gradient">
                  <BandwidthAmount bandwidth={data.data.dia_bw} />
                </Badge>
              </td>
            </tr>
            {data.data.circuits.map((circuit, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => toggleVisibility(index)}
                  style={{ cursor: "pointer" }}
                >
                  <th>{circuit.role} Circuit</th>
                  <td>
                    {circuit.cid}
                    {visibileCircuits[index] ? (
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        className="float-end mt-1"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-end mt-1"
                      />
                    )}
                  </td>
                </tr>
                {visibileCircuits[index] && (
                  <>
                    <tr style={{ backgroundColor: "#F7F7F7" }}>
                      <th className="ps-4 text-sm" style={{ color: "gray" }}>
                        CDR
                      </th>
                      <td className="text-sm" style={{ color: "gray" }}>
                        <BandwidthAmount bandwidth={circuit.cdr} />
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#F7F7F7" }}>
                      <th className="ps-4 text-sm" style={{ color: "gray" }}>
                        Provider
                      </th>
                      <td className="text-sm" style={{ color: "gray" }}>
                        {circuit.provider}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#F7F7F7" }}>
                      <th className="ps-4 text-sm" style={{ color: "gray" }}>
                        Type
                      </th>
                      <td className="text-sm" style={{ color: "gray" }}>
                        {circuit.type}
                      </td>
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
            <tr style={{ borderBottom: "none" }}>
              <th style={{ borderBottom: "none" }}>
                <span className="text-sm" style={{ color: "silver" }}>
                  Data Source
                </span>
              </th>
              <td style={{ borderBottom: "none" }}>
                <span className="text-sm" style={{ color: "silver" }}>
                  {data.data_source.charAt(0).toUpperCase() +
                    data.data_source.slice(1)}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default BuildingNetworkInfo;

const statusOptions = [
  { value: "Customer Responded", label: "Customer Responded" },
  { value: "Customer Responded*", label: "Customer Responded*" },
  { value: "In Progress", label: "In Progress" },
  { value: "On-Hold", label: "On-Hold" },
  { value: "Waiting on Customer", label: "Waiting on Customer" },
  { value: "Customer Chase Sent*", label: "Customer Chase Sent*" },
  { value: "Responded", label: "Responded" },
  { value: "Resolved", label: "Resolved" },
  { value: "Resolved*", label: "Resolved*" },
  { value: "New", label: "New" },
  { value: "Closed", label: "Closed" },
  { value: "Closed - No Response*", label: "Closed - No Response*" },
  { value: "Awaiting PIR", label: "Awaiting PIR" },
];

export default statusOptions;

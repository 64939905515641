import { useContext, useState } from "react";
import PermissionsContext from "../contexts/PermissionsContext";
import useAuth from "./useAuth";

const usePermissions = (permission) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState();
  const { user, isAuthenticated } = useAuth();

  const { isAllowedTo } = useContext(PermissionsContext);

  if (isAuthenticated && user) {
    isAllowedTo(permission).then((allowed) => {
      setLoading(false);
      setAllowed(allowed);
    });
  }

  return [loading, allowed];
};

export default usePermissions;

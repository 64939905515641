import axios from "axios";
import { apiConfig } from "../../config";

let rolePermissions = axios
  .get(apiConfig.usersApiUrl + "permissions")
  .then((response) => {
    return (rolePermissions = response.data);
  });

export { rolePermissions };

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const coreMonitoringSlice = createSlice({
  name: "coremonitoring",
  initialState,
  reducers: {
    setCoreMonitoringData: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setCoreMonitoringData } = coreMonitoringSlice.actions;

export default coreMonitoringSlice.reducer;

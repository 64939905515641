import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import landlordReducer from "./slices/landlordswitcher";
import tenantNetworkReducer from "./slices/tenantnetwork";
import coordinatesReducer from "./slices/coordinates";
import customersReducer from "./slices/customers";
import filtersReducer from "./slices/filters";
import refreshDataReducer from "./slices/refreshData";
import coreMonitoringReducer from "./slices/coremonitoring";
import pageLoadingReducer from "./slices/pageloading";
import accountReducer from "./slices/accountswitcher";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    landlordswitcher: landlordReducer,
    accountswitcher: accountReducer,
    coordinates: coordinatesReducer,
    customers: customersReducer,
    searchfilters: filtersReducer,
    refreshdata: refreshDataReducer,
    coremonitoring: coreMonitoringReducer,
    pageloading: pageLoadingReducer,
    tenantnetwork: tenantNetworkReducer,
  },
});

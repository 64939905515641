import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const NotAuthorised = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="text-center p-4">
        <h1 className="display-1 fw-bold">401</h1>
        <p className="h1">Not authorised.</p>
        <p className="h2 fw-normal mt-3 mb-4">
          Your user role does not have the correct permissions to view this
          function.
        </p>
        <Button variant="primary" size="lg" onClick={() => navigate(-1)}>
          Go back
        </Button>
      </div>
    </React.Fragment>
  );
};

export default NotAuthorised;

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Button,
  Container,
  Card,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Modal,
} from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../config";

import moment from "moment";

import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";

import MultiPageForm from "../../components/modals/MultiPageForm";
import formConfig from "./formConfigs/customerMoveIn";

// import { LazyLog } from "react-lazylog";
// import { quantum } from "ldrs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import NetworkAutomationResult from "./NetworkAutomationResult";
import WorkflowStatusBadge from "./WorkflowStatusBadge";

// quantum.register();

const NetworkAutomationOverview = () => {
  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  const [passedResult, setPassedResult] = useState({});
  const [refreshData, setRefreshData] = useState(0);

  // const statusStyleMap = {
  //   completed: "success",
  //   failed: "danger",
  //   running: "info",
  // };

  const tableColumns = [
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: (e) => moment(e.value).local().format("HH:mm:ss DD-MM-YYYY"),
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Customer",
      accessor: "customer",
    },
    {
      Header: "Status",
      accessor: "status",
      // Cell: (e) => (
      //   <Badge bg={statusStyleMap[e.value]}>
      //     {e.value === "running" ? <l-quantum size="12" color="white" /> : ""}
      //     {e.value}
      //   </Badge>
      // ),
      Cell: (e) => <WorkflowStatusBadge status={e.value} />,
    },
    {
      Header: "Results",
      accessor: "result",
    },
    {
      Header: "Manifest",
      accessor: "manifest",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => {
        return (
          <Button
            size="sm"
            className="btn-light"
            onClick={() => {
              toggle("viewAutomationResult");
              setPassedResult(tableProps.row.original);
            }}
            disabled={!tableProps.row.original.result}
          >
            {tableProps.row.original.result
              ? "View Results"
              : "Results Pending"}
          </Button>
        );
      },
    },
  ];

  // const tableData = [
  //   {
  //     id: "136905a5-12d4-4d24-bd3f-7645869d91af",
  //     created_at: "11:05:42 11/08/2024",
  //     location: "Unit 1.01 @ Record Hall",
  //     customer: "ABC Corp",
  //     status: "Completed",
  //     actions: "View Details",
  //   },
  //   {
  //     id: "136905a5-12d4-4d24-bd3f-7645869d91af",
  //     created_at: "11/08/2022",
  //     location: "Unit LG.02 @ The Leather Market",
  //     customer: "XYZ Limited",
  //     status: "Failed",
  //     actions: "View Details",
  //   },
  //   {
  //     id: "136905a5-12d4-4d24-bd3f-7645869d91af",
  //     created_at: "11/08/2022",
  //     location: "Unit 5.04 @ Leroy House",
  //     customer: "Wayne Enterprises",
  //     status: "Running",
  //     actions: "View Details",
  //   },
  // ];

  // const loading = false;
  // const noData = false;

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    setLoading(true);
    axios
      .get(
        apiConfig.monitoringApiUrl + "automation/workflows/history",
        axiosConfig
      )
      .then(function (response) {
        setLoading(false);
        setTableData(response?.data.rows);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        setTableData([]);
        console.log(error);
      });
  }, [bearerToken, isAuthenticated, refreshData]);

  return (
    <>
      <Helmet title="Network Automation" />
      <Container fluid className="p-0">
        <Breadcrumb className="float-end mt-2">
          <Breadcrumb.Item href="/dashboard/default">Home</Breadcrumb.Item>
          <Breadcrumb.Item>Network Automation</Breadcrumb.Item>
          <Breadcrumb.Item active>Overview</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          Network Automation
          <span className="text-muted"></span>
        </h1>
        <div className="clearfix"></div>
        <Row>
          <Col xl="9">
            <Card>
              <Card.Header>
                <div className="card-actions float-end">
                  <Button
                    variant="outline-primary"
                    className="me-2"
                    onClick={() => setRefreshData(!refreshData)}
                  >
                    <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                  </Button>
                  <DropdownButton
                    as={ButtonGroup}
                    id={`dropdown-variants-primary`}
                    variant={`primary`}
                    title="Run An Automation "
                    align="end"
                  >
                    <Dropdown.Item onClick={() => toggle("customerMoveIn")}>
                      Provision Customer Network
                    </Dropdown.Item>
                    <Dropdown.Item
                    // onClick={() => toggle("wifiPurchase")}
                    >
                      De-provision Customer Network
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
                <Card.Title className="mb-0">Workflow History</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                {loading && (
                  <>
                    <LogoLoader className="d-block m-auto p-4" />
                  </>
                )}
                {!loading && (
                  <ColumnFilteringTable
                    columns={tableColumns}
                    data={tableData}
                    hiddenColumns={["result", "manifest"]}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3"></Col>
        </Row>
      </Container>
      <Modal
        show={openModals["viewAutomationResult"]}
        onHide={() => {
          toggle("viewAutomationResult");
          setPassedResult({});
        }}
        size="xl"
        centered
        // scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Automation Results</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2">
          {/* <LazyLog
            text={
              passedResult && passedResult.result
                ? JSON.stringify(JSON.parse(passedResult.result), null, 2)
                : ""
            }
            selectableLines={true}
            height={"500"}
            enableSearch={true}
            extraLines={10}
            follow={true}
          /> */}
          <NetworkAutomationResult
            passedResult={passedResult}
            // workflowName={passedResult.workflow_name}
            // passedManifest={
            //   passedResult && passedResult.manifest
            //     ? JSON.parse(passedResult.manifest)
            //     : ""
            // }
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <MultiPageForm
        openModals={openModals}
        toggle={toggle}
        config={formConfig}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
      />
    </>
  );
};

export default NetworkAutomationOverview;

import * as Yup from "yup";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faMagnifyingGlass,
//   faWandMagicSparkles,
// } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { apiConfig } from "../../../config";

const taskHandlers = {
  "Review next steps": () => {
    return new Promise((resolve, reject) => {
      resolve({ message: "Completed" });
    });
  },
  "Create registration form (new)": (
    prevousTask,
    token,
    { myFormData, updateHandler, task, user, selectedLandlord }
  ) => {
    myFormData["assigned_to"] = user.email;
    myFormData["operator_id"] = selectedLandlord.landlordid;

    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.hubspotApiUrl}companies/signups`, myFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({
            message: "Completed",
            ctaLink: {
              link: `/customers/registrations/edit/${response.data.id}`,
              label: "View New Draft",
            },
            data: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  "No action required": () => {
    return new Promise((resolve, reject) => {
      resolve({ message: "Completed" });
    });
  },
  "Create registration form (update)": (
    prevousTask,
    token,
    { myFormData, updateHandler, task, user, selectedLandlord }
  ) => {
    myFormData["assigned_to"] = user.email;
    myFormData["operator_id"] = selectedLandlord.landlordid;

    return new Promise((resolve, reject) => {
      axios
        .post(`${apiConfig.hubspotApiUrl}companies/signups`, myFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({
            message: "Completed",
            ctaLink: {
              link: `/customers/registrations/edit/${response.data.id}`,
              label: "View New Draft",
            },
            data: response.data,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const formConfig = {
  name: "customerRegistration",
  title: "NEW CUSTOMER REGISTRATION",
  steps: [
    {
      stepType: "input",
      title: "Customer Details",
      description:
        "Provide some details about the customer you wish to register",
      longDescription:
        "Provide some details about the customer you wish to register. We'll use these to firstly check if the customer already exists in Wavenet's systems.",
      fields: [
        {
          name: "company_name",
          label: "Company Name",
          confirmLabel: "Company Name",
          type: "text",
        },
        {
          name: "company_domain",
          label: "Company Domain Name",
          confirmLabel: "Domain Name",
          type: "text",
        },
        {
          name: "company_reg_number",
          label: "Company Registration Number",
          confirmLabel: "Company Registration Number",
          type: "text",
        },
      ],
      validationSchema: Yup.object().shape({
        company_name: Yup.string()
          .required("Company name is required")
          .nullable(false),
        company_domain: Yup.string().nullable(true),
        company_reg_number: Yup.number()
          .positive()
          .integer()
          .nullable(true)
          .typeError("Must be a number"),
      }),
    },
    {
      stepType: "externalQuery__hubspot",
      title: "Find Existing Records",
      description: "Check if the customer already exists in Wavenet's systems",
      longDescription:
        "Checking if the customer already exists in Wavenet's systems by querying CRM for matches.",
      fields: [
        {
          name: "hubspot_id",
          label: "Existing Company Hubspot ID",
          confirmLabel: "Existing Company Hubspot ID",
          type: "radiotile",
        },
      ],
      validationSchema: Yup.object().shape({
        hubspot_id: Yup.string()
          .required("Please make a selection")
          .nullable(false),
      }),
      query: {
        url: `/api/customers/exists`,
      },
      stepPermission: "view:customerss:all",
      queryHandler: (
        companyName,
        companyDomain,
        companyRegNumber,
        userAccessToken,
        setLoadingExternalQuery,
        setExternalQueryResults
      ) => {
        console.log("Query handler called");

        setLoadingExternalQuery(true);

        axios
          .get(
            `${apiConfig.hubspotApiUrl}companies/possiblecompanies?company_name=${companyName}&company_domain=${companyDomain}&company_registration_number=${companyRegNumber}`,
            {
              headers: {
                Authorization: `Bearer ${userAccessToken}`,
              },
            }
          )
          .then((response) => {
            setLoadingExternalQuery(false);
            setExternalQueryResults(response.data.companies);
          })
          .catch((error) => {
            setLoadingExternalQuery(false);
            setExternalQueryResults([]);
            console.log(error.response);
          });
        return null;
      },
    },
    // {
    //   stepType: "review",
    //   title: "Confirm Details",
    //   confirmationStep: true,
    //   description: "Review details and request new customer record",
    //   fields: [
    //     {
    //       name: "confirm",
    //       label: "Confirm that the below details are correct",
    //       type: "confirm",
    //       validation: null,
    //     },
    //   ],
    //   validationSchema: null,
    // },
    {
      stepType: "process",
      title: "Finish",
      description: "",
      confirmationStep: true,
      fields: [],
    },
  ],
  handleSubmit: async (
    formData,
    {
      setSubmissionStatus,
      userAccessToken,
      updateTaskStatus,
      setIsConfirmationNeeded,
      isConfirmed,
    }
  ) => {
    console.log("Attempting to submit form data:", formData);

    const tasks = []; // Define your tasks

    if (formData.hubspot_id === "None") {
      tasks.push(
        {
          name: "Review next steps",
          status: "pending",
          description:
            "See below for the next steps. If you're happy to proceed, click the button below.",
          continueText: "Create Registration Form",
        },
        {
          name: "Create registration form (new)",
          description:
            "No matching customers were found in Wavenet's systems. We'll open a new customer registration form to register the customer details in the CRM.",
          status: "pending",
        }
      );
      setIsConfirmationNeeded(true);
    } else if (formData.hubspot_id.includes("*")) {
      tasks.push({
        name: "No action required",
        description:
          "Customer already has an account number in Wavenet's systems, you don't need to register this customer",
        status: "no_action",
      });
      setIsConfirmationNeeded(false);
    } else {
      tasks.push(
        {
          name: "Review next steps",
          status: "pending",
          description:
            "See below for the next steps. If you're happy to proceed, click the button below.",
          continueText: "Create Registration Form",
        },
        {
          name: "Create registration form (update)",
          description:
            "A customer was found in Wavenet's CRM but they don't have an account number yet. We'll open a customer registration form with the existing details, to complete the registration process.",
          status: "pending",
        }
      );
      setIsConfirmationNeeded(true);
    }

    // Initialize an empty array for tasks to start with a clean state
    setSubmissionStatus({
      isLoading: true,
      tasks: tasks.map((task) => ({ ...task, status: task.status })),
    });

    const firstTask = tasks[0];
    const handler = taskHandlers[firstTask.name];
    updateTaskStatus(firstTask.name, "loading", null, "");

    try {
      const result = await handler(formData, userAccessToken);
      updateTaskStatus(
        firstTask.name,
        "succeeded",
        result.message,
        JSON.stringify(result.manifest, null, 2),
        result.ctaLink
      );

      // if (isConfirmed) {
      //   tasks.slice(1).forEach((task) => {
      //     updateTaskStatus(task.name, "loading", null, null);

      //     setTimeout(() => {
      //       updateTaskStatus(
      //         task.name,
      //         "succeeded",
      //         "Completed configuration successfully",
      //         {}
      //       );
      //     }, 5000);
      //   });
      // }
    } catch (error) {
      updateTaskStatus(
        firstTask.name,
        "failed",
        error.message,
        null,
        error.ctaLink
      );
    }
  },
  executeNextTask: async ({
    helpers,
    submissionStatus,
    taskIndex,
    setSubmissionStatus,
  }) => {
    const {
      updateTaskStatus,
      userAccessToken,
      myFormData,
      user,
      selectedLandlord,
    } = helpers;
    console.log("taskIndex: ", taskIndex);
    const task = submissionStatus.tasks[taskIndex];
    const previousTask = submissionStatus.tasks[taskIndex - 1];
    const handler = taskHandlers[task.name];
    updateTaskStatus(task.name, "loading", null, "");
    try {
      const result = await handler(previousTask.payload, userAccessToken, {
        task: task,
        updateHandler: updateTaskStatus,
        submissionStatusHandler: setSubmissionStatus,
        submissionStatus: submissionStatus,
        myFormData: myFormData,
        user: user,
        selectedLandlord: selectedLandlord,
      });
      updateTaskStatus(
        task.name,
        "succeeded",
        result.message,
        result.payload,
        result.ctaLink
      );
    } catch (error) {
      console.log("failed payload: ", error);
      updateTaskStatus(
        task.name,
        "failed",
        error.message,
        error.payload,
        error.ctaLink
      );
    }
  },
  executeAllTasks: async ({
    helpers,
    submissionStatus,
    setSubmissionStatus,
  }) => {
    for (
      let i = submissionStatus.tasks.findIndex(
        (task) => task.status === "pending"
      );
      i < submissionStatus.tasks.length;
      i++
    ) {
      await formConfig.executeNextTask({
        helpers,
        submissionStatus,
        taskIndex: i,
        setSubmissionStatus,
      });
    }
  },
};

export default formConfig;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_account: "",
  accounts: [],
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.current_account = action.payload.current_account;
      state.accounts = action.payload.accounts;
      return state;
    },
  },
});

export const { setAccount } = accountSlice.actions;

export default accountSlice.reducer;

import React from "react";

// import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const DatePicker = ({ selectedDate, setSelectedDate }) => {
  return (
    <DayPicker
      mode="single"
      selected={selectedDate}
      onSelect={(day) => setSelectedDate(day)}
      fromDate={new Date()}
      modifiersStyles={{
        selected: { background: "#3F80EA" },
      }}
    />
  );
};

export default DatePicker;

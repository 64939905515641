import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
};

export const pageLoadingSlice = createSlice({
  name: "pageLoading",
  initialState,
  reducers: {
    setPageLoading: (state, action) => {
      state.loading = action.payload.isPageLoading;
      return state;
    },
  },
});

export const { setPageLoading } = pageLoadingSlice.actions;

export default pageLoadingSlice.reducer;

import React from "react";

const BandwidthAmount = ({ bandwidth }) => {
  const formatBandwidth = (bandwidth) => {
    if (!bandwidth) {
      return "--";
    }
    if (bandwidth >= 1000) {
      return `${bandwidth / 1000} Gbps`;
    } else if (bandwidth >= 1) {
      return `${bandwidth} Mbps`;
    } else {
      return `${bandwidth * 1000} Kbps`;
    }
  };

  return <>{formatBandwidth(bandwidth)}</>;
};

export default BandwidthAmount;

import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import Select from "react-select";
import useAuth from "../../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../../config";
import NotyfContext from "../../../../contexts/NotyfContext";

const PurchaseAdditionalUsersModal = ({
  openModals,
  toggle,
  username,
  customerOptions,
}) => {
  const notyf = useContext(NotyfContext);
  const [type] = useState("success");
  const [duration] = useState("30000");
  const [ripple] = useState(true);
  const [dismissible] = useState(true);
  const [positionX] = useState("right");
  const [positionY] = useState("top");
  const [excellid, setExcellId] = useState(null);
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  useEffect(() => {
    if (customerOptions.length === 1) {
      setExcellId(customerOptions[0].value);
    }
  }, [customerOptions]);

  const [quantity, setQuantity] = useState(5);
  const pricePerFive = 5.72;
  const resetFormFields = () => {
    setQuantity(5);
  };

  const handlePurchase = (event) => {
    event.preventDefault();
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    const purchaseBody = {
      account_number: excellid,
      requester: username,
      quantity_requested: quantity,
    };
    axios
      .post(
        `${apiConfig.dot11ApiUrl}users/additionalUsersPurchase`,
        purchaseBody,
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        } else {
          notyf.open({
            type: "warning",
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
        }
      })
      .catch((err) => {
        notyf.open({
          type: "warning",
          message: err.response.data.error,
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        console.log(err);
      });
    toggle("wifiPurchase");
    resetFormFields();
    setExcellId(null);
  };

  return (
    <Modal
      show={openModals["wifiPurchase"]}
      onHide={() => {
        toggle("wifiPurchase");
        resetFormFields();
        setExcellId(null);
      }}
      centered
    >
      <Modal.Header closeButton>
        <b>Admin Function:</b>&nbsp; Purchase Additional Wi-Fi Accounts
      </Modal.Header>
      <Modal.Body className="m-3">
        <p className="text-left text-lg my-0">
          If you would like to add additional 802.1X wi-fi accounts to your
          available allocation, please use the form below.
        </p>
        <hr />
        <Form.Group className="mb-3 row">
          <Form.Label className="col-sm-6 col-form-label">
            Request increased user allocation for:
          </Form.Label>
          <div className="col-sm-6">
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={customerOptions}
              onChange={(e) => {
                setExcellId(e.value);
                resetFormFields();
              }}
              defaultValue={
                customerOptions.length === 1 ? customerOptions[0] : null
              }
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 row">
          <Form.Label className="col-sm-6 col-form-label">
            Quantity of additional Wi-Fi Accounts:
          </Form.Label>
          <div className="col-sm-6">
            <Form.Control
              type="number"
              step="5"
              min="5"
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
            />
          </div>
        </Form.Group>

        <Alert variant="info" className="p-2">
          Total: £{((quantity / 5) * pricePerFive).toFixed(2)} per month.
        </Alert>
        <p>
          By proceeding, your request will be sent via email to the sales team
          to process an order for you. Your account manager will be in touch
          with your billing contact to confirm the order.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            toggle("wifiPurchase");
            resetFormFields();
            setExcellId(null);
          }}
        >
          Cancel
        </Button>
        <Button variant="info" onClick={handlePurchase} disabled={!excellid}>
          Request Additional Accounts
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PurchaseAdditionalUsersModal;

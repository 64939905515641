import React from "react";

import { ButtonGroup, Dropdown, Badge } from "react-bootstrap";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FilterDropdowns = ({ filterOptions, onSelectFilter }) => {
  return Object.keys(filterOptions).map((key, index) => {
    const filter = filterOptions[key];
    return (
      <Dropdown as={ButtonGroup} className="me-2" size="md" key={key}>
        <Dropdown.Toggle variant="light" id={`${key}-dropdown`}>
          {filter.label}
        </Dropdown.Toggle>

        <Dropdown.Menu className="scrollable-dropdown">
          {filter.options.map((option, optionIndex) => (
            <Dropdown.Item
              key={optionIndex}
              onClick={() => onSelectFilter(option, filter.accessor)}
            >
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  });
};

const InventorySelectorFilters = ({ filters, setFilters, filterOptions }) => {
  const handleSelectFilter = (selectedFilter, accessor) => {
    const newFilter = { id: accessor, value: selectedFilter };
    if (
      !filters.some(
        (filter) =>
          filter.id === newFilter.id && filter.value === newFilter.value
      )
    ) {
      setFilters([...filters, newFilter]);
    }
  };
  const handleRemoveFilter = (filter) => {
    setFilters(filters.filter((f) => f !== filter));
  };

  return (
    <>
      <FilterDropdowns
        filterOptions={filterOptions}
        onSelectFilter={handleSelectFilter}
      />
      <div className="my-3">
        {filters.map((filter, index) => (
          <Badge key={index} bg="secondary" className="me-2">
            {filter.value}
            <FontAwesomeIcon
              icon={faTimes}
              className="ms-2"
              onClick={() => handleRemoveFilter(filter)}
              style={{ cursor: "pointer" }}
            />
          </Badge>
        ))}
      </div>
    </>
  );
};

export default InventorySelectorFilters;

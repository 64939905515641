import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

import { apiConfig } from "../../../config";
import { ReactComponent as LogoLoader } from "../../../assets/img/wavenet-animated-loader3.svg";

import NotFound from "../../../components/NotFound";
import ColumnSortingTable from "../../../components/tables/ColumnSortingTable";

import useAuth from "../../../hooks/useAuth";

const TopOutstandingTicketsTable = () => {
  const { userAccessToken } = useAuth();
  const [tableData, setTableData] = useState([]);
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.ticketsApiUrl +
          "tickets/list?operator=" +
          selectedLandlord.landlordid +
          "&prolonged=true",
        axiosConfig
      )
      .then(function (response) {
        setLoading(false);
        setTableData(response.data);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }, [bearerToken, selectedLandlord]);

  const tableColumns = [
    {
      Header: "Reference",
      accessor: "TicketID",
      Cell: (e) => (
        <div className="text-center">
          <Link to={"/tickets/view/" + e.value}>
            <Button variant="outline-primary">{e.value}</Button>
          </Link>
        </div>
      ),
    },
    {
      Header: "Description",
      accessor: "TicketSummary",
    },
    {
      Header: "Customer",
      accessor: "CustomerName",
    },
    {
      Header: "Open Since",
      accessor: "OpenSince",
      Cell: (e) => moment(e.value).local().format("DD-MM-YYYY HH:mm"),
    },
  ];
  return (
    <Card className="flex-fill w-100">
      <Card.Header className="mb-0 pb-0">
        <Card.Title className="mb-0">Top Outstanding Tickets</Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && <LogoLoader className="d-block m-auto p-4" />}
        {!loading && noData && <NotFound />}
        {!loading && !noData && (
          <ColumnSortingTable columns={tableColumns} data={tableData} />
        )}
      </Card.Body>
    </Card>
  );
};

export default TopOutstandingTicketsTable;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  ProgressBar,
  Row,
  Breadcrumb,
} from "react-bootstrap";

import OffcanvasHelp from "../../components/OffcanvasHelp";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";

import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBuilding,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";

import { apiConfig } from "../../config";

const PLACEHOLDER_IMAGE = "/assets/img/buildings/placeholder.jpeg";

const Building = ({
  name,
  status,
  color = "secondary",
  percentage,
  numrooms,
  numcustomers,
  technicalreference,
  buildingid,
}) => {
  const getBuildingImagePath = () => {
    const path = `/assets/img/buildings/${technicalreference}.webp`;
    const img = new Image();
    img.src = path;
    img.onerror = () => (img.src = PLACEHOLDER_IMAGE);
    return img.src;
  };

  const handleError = (event) => {
    event.target.onerror = null;
    event.target.src = PLACEHOLDER_IMAGE;
  };

  return (
    <Card>
      <Link to={`/buildings/profile/${buildingid}`}>
        <Card.Img
          src={getBuildingImagePath()}
          alt="Building Image"
          className="card-img-aspect"
          onError={handleError}
        />
        <div className="card-img-overlay">
          <Badge
            className={
              status === "Live Building" ? "my-2 btn-gradient" : "my-2"
            }
            bg={color}
          >
            {status}
          </Badge>
        </div>
        <Card.Header className="px-4 pt-4">
          <Card.Title className="mb-0">{name}</Card.Title>
        </Card.Header>
      </Link>
      <Card.Body className="px-4 pt-1 pb-1">
        <p className="mb-0">
          <span className="pe-2 text-nowrap">
            <FontAwesomeIcon icon={faBuilding} />
            &nbsp;<b>{numrooms}</b> Rooms
          </span>
          <span className="text-nowrap">
            <FontAwesomeIcon icon={faUserCircle} />
            &nbsp;<b>{numcustomers}</b> Customers
          </span>
        </p>
      </Card.Body>
      <ListGroup variant="flush">
        <ListGroup.Item className="px-4 pb-4">
          <p className="mb-0 fw-bold">
            Occupancy<span className="float-end">{percentage}%</span>
          </p>
          <ProgressBar className="progress-sm gradient" now={percentage} />
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const Buildings = () => {
  const { userAccessToken } = useAuth();
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const bearerToken = `Bearer ${userAccessToken}`;
  const [buildingsData, setBuildingsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    const apiUrl = `${apiConfig.connectedApiUrl}buildings?page_size=1000&order_by=building_name%20ASC&building_operator=${selectedLandlord.landlordid}`;

    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setLoading(false);
        setNoData(false);
        setBuildingsData(response.data.buildings.items);
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
      });
  }, [bearerToken, selectedLandlord]);

  const buildingsList = buildingsData
    .filter((e) => e.status === "Live Building")
    .map((e) => (
      <Col md="6" lg="3" key={e.id}>
        <Building
          name={e.building_name}
          status={e.status}
          color={e.status === "Live Building" ? "success" : "secondary"}
          percentage={
            Math.round((e?.live_tenancies_count / e?.rooms_count) * 100) || 0
          }
          description={e.description}
          image=""
          buildingid={e.id}
          numrooms={e?.rooms_count || 0}
          numcustomers={e?.live_tenancies_count || 0}
          technicalreference={e.wifi_id}
        />
      </Col>
    ));

  const navigate = useNavigate();

  return (
    <IsAllowed to="view:buildings:ui" fallback={<NotAuthorised />}>
      <Helmet title="Building Portfolio" />
      <Container fluid className="p-0">
        <Breadcrumb className="float-end mt-2">
          <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Building Portfolio</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          Building Portfolio
          <OffcanvasHelp
            id="buildingportfolio"
            name="Building Portfolio"
            scroll
            backdrop
          />
        </h1>
        <IsAllowed to="create:buildings">
          <Link to="/buildings/new">
            <Button variant="primary" className="mt-n1 mb-3">
              <FontAwesomeIcon icon={faPlus} /> New Building
            </Button>
          </Link>
        </IsAllowed>
        <div className="clearfix"></div>
        <Row className="d-flex">
          {loading && <LogoLoader className="d-block m-auto p-4" />}
          {!loading && noData && <NotFound />}
          {!loading && !noData && buildingsList}
        </Row>
      </Container>
    </IsAllowed>
  );
};

export default Buildings;

import React, { useState } from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Button,
  Badge,
  Dropdown,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";

// import useAuth from "../../hooks/useAuth";
// import { apiConfig } from "../../config";
// import axios from "axios";
import moment from "moment";

import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";
import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";
import NotFound from "../../components/NotFound";
import MultiPageForm from "../../components/modals/MultiPageForm";
import formConfig from "./formConfigs/customerOrder";

const CustomerOrdersList = () => {
  // Auth
  // const { userAccessToken, isAuthenticated } = useAuth();
  // const bearerToken = `Bearer ${userAccessToken}`;

  // State
  // const selectedLandlord = useSelector((state) => state.landlordswitcher);
  // const [loading, setLoading] = useState(false);
  // const [noData, setNoData] = useState(false);
  const [loading] = useState(false);
  const [noData] = useState(false);
  // const [registrationsData, setRegistrationsData] = useState([]);
  const [refreshData, setRefreshData] = useState(0);

  // Modals
  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  // Utils

  const navigate = useNavigate();

  // // Effects
  // React.useEffect(() => {
  //   const axiosConfig = {
  //     headers: { Authorization: bearerToken },
  //   };
  //   if (!isAuthenticated) {
  //     return;
  //   }
  //   axios
  //     .get(
  //       `${apiConfig.symbillApiUrl}signups/operator/${selectedLandlord.landlordid}`,
  //       axiosConfig
  //     )
  //     .then((response) => {
  //       setRegistrationsData(response.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setNoData(true);
  //       setLoading(false);
  //       setRegistrationsData([]);
  //     });
  // }, [isAuthenticated, bearerToken, selectedLandlord.landlordid]);

  // Constants
  const statusColourMap = {
    draft: "secondary",
    pending: "primary",
    complete: "info",
    approved: "success",
    rejected: "danger",
  };
  const registrationsListColumns = [
    {
      Header: "Company Name",
      accessor: "company_name",
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (e) => (
        <Badge bg={statusColourMap[e.value]}>
          {e.row.original.status.charAt(0).toUpperCase() +
            e.row.original.status.slice(1)}
        </Badge>
      ),
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: (e) => moment(e.value).local().format("DD-MM-YYYY"),
    },
    {
      Header: "Updated",
      accessor: "updated",
      Cell: (e) =>
        e.value ? moment(e.value).local().format("DD-MM-YYYY") : "",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: (tableProps) => {
        return (
          <Button
            size="sm"
            className="btn-light"
            onClick={() => {
              navigate(
                `/customers/registrations/edit/${tableProps.row.original.id}`
              );
            }}
          >
            View / Edit
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <Card>
        <Card.Header>
          <div className="card-actions float-end">
            <DropdownButton
              as={ButtonGroup}
              id={`dropdown-variants-primary`}
              variant={`primary`}
              title="Actions "
              align="end"
            >
              <Dropdown.Item onClick={() => toggle("customerOrder")}>
                New Customer Order
              </Dropdown.Item>
            </DropdownButton>
          </div>
          <Card.Title className="mb-0">Customer Orders List</Card.Title>
        </Card.Header>
        <Card.Body className="py-0">
          {loading && (
            <>
              <LogoLoader className="d-block m-auto p-4" />
            </>
          )}
          {!loading && noData && <NotFound />}
          {!loading && !noData && (
            <ColumnFilteringTable
              columns={registrationsListColumns}
              data={[]}
            />
          )}
        </Card.Body>
      </Card>
      <MultiPageForm
        openModals={openModals}
        toggle={toggle}
        config={formConfig}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
      />
    </>
  );
};

export default CustomerOrdersList;

import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Row } from "react-bootstrap";

import illustration from "../../../assets/img/illustrations/gateway-logo.png";

import useAuth from "../../../hooks/useAuth";

const Welcome = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Row>
      <Col md="12" xl className="d-flex">
        <Card className="illustration flex-fill">
          <Card.Body className="p-0 d-flex flex-fill">
            <Row className="g-0 w-100">
              <Col xs="6">
                <div className="illustration-text p-3 m-1">
                  <h4 className="illustration-text">
                    {t("Welcome back")}, {user.displayName}
                  </h4>
                  <p className="mb-0">
                    You are logged in to the Wavenet Connected Gateway
                    application.
                  </p>
                  {/*<br />
                  [DEBUG] Access Token:
                  <pre>{userAccessToken}</pre>
                  <pre>{replyText}</pre>*/}
                </div>
              </Col>
              <Col xs={6} className="align-self-end text-end">
                <img
                  src={illustration}
                  alt="Customer Support"
                  className="img-fluid illustration-img"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Welcome;

import React from "react";

import { Card, Row, Col, Button } from "react-bootstrap";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";

import IsAllowed from "./IsAllowed";

import { useSelector } from "react-redux";

const SupportOptions = {
  default: {
    billing: {
      type: "billing",
      phone: "020 3927 1114",
      email: "billingconnected@wavenetuk.com",
      description:
        "Contact the Customer Experience team using the details below",
    },
    commercial: {
      type: "commercial",
      phone: "020 3927 1113",
      email: "connectedspaces@wavenetuk.com",
      description:
        "Contact the Account Management team using the details below",
    },
    technical: {
      type: "technical",
      phone: "03701 665566",
      email: "workspace-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
    support: {
      type: "support",
      phone: "03701 665566",
      email: "workspace-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
    wifi: {
      type: "wifi",
      phone: "03701 665566",
      email: "workspace-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
  },
  2: {
    billing: {
      type: "billing",
      phone: "020 3927 1114",
      email: "billingconnected@wavenetuk.com",
      description:
        "Contact the Customer Experience team using the details below",
    },
    commercial: {
      type: "commercial",
      phone: "020 3927 1113",
      email: "connectedspaces@wavenetuk.com",
      description:
        "Contact the Account Management team using the details below",
    },
    technical: {
      type: "technical",
      phone: "03701 665566",
      email: "workspace-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
    support: {
      type: "support",
      phone: "03701 665566",
      email: "workspace-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
    wifi: {
      type: "wifi",
      phone: "03701 665566",
      email: "workspace-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
  },
  19: {
    billing: {
      type: "billing",
      phone: "020 3927 1114",
      email: "billingconnected@wavenetuk.com",
      description:
        "Contact the Customer Experience team using the details below",
    },
    commercial: {
      type: "commercial",
      phone: "020 3927 1113",
      email: "connectedspaces@wavenetuk.com",
      description:
        "Contact the Account Management team using the details below",
    },
    technical: {
      type: "technical",
      phone: "03701 665566",
      email: "britishland-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
    support: {
      type: "support",
      phone: "03701 665566",
      email: "britishland-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
    wifi: {
      type: "wifi",
      phone: "03701 665566",
      email: "britishland-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
  },
  43: {
    billing: {
      type: "billing",
      phone: "020 3927 1114",
      email: "billingconnected@wavenetuk.com",
      description:
        "Contact the Customer Experience team using the details below",
    },
    commercial: {
      type: "commercial",
      phone: "020 3927 1113",
      email: "connectedspaces@wavenetuk.com",
      description:
        "Contact the Account Management team using the details below",
    },
    technical: {
      type: "technical",
      phone: "03701 665566",
      email: "workspace-support@wavenetuk.com",
      description: "Contact the Service Desk team using the details below",
    },
    support: {
      type: "support",
      phone: "0121 387 1900",
      email: "arterial-support@wavenet.co.uk",
      description: "Contact the Service Desk team using the details below",
    },
    wifi: {
      type: "wifi",
      phone: "0121 387 1900",
      email: "arterial-support@wavenet.co.uk",
      description: "Contact the Service Desk team using the details below",
    },
  },
  34: {
    billing: {
      type: "billing",
      phone: "020 3927 1114",
      email: "billingconnected@wavenetuk.com",
      description:
        "Contact the Customer Experience team using the details below",
    },
    commercial: {
      type: "commercial",
      phone: "020 3927 1113",
      email: "connectedspaces@wavenetuk.com",
      description:
        "Contact the Account Management team using the details below",
    },
    technical: {
      type: "technical",
      phone: "03701 665566",
      email: "gpe-support@wavenet.co.uk",
      description: "Contact the Service Desk team using the details below",
    },
    support: {
      type: "support",
      phone: "0121 387 1900",
      email: "gpe-support@wavenet.co.uk",
      description: "Contact the Service Desk team using the details below",
    },
    wifi: {
      type: "wifi",
      phone: "0121 387 1900",
      email: "gpe-support@wavenet.co.uk",
      description: "Contact the Service Desk team using the details below",
    },
  },
};

const GetSupport = (props) => {
  const { landlordid } = useSelector((state) => state.landlordswitcher);
  const helpTypes =
    SupportOptions[
      Object.keys(SupportOptions).find((key) => key === landlordid.toString())
    ] || SupportOptions["default"];
  const helpType = helpTypes[props.type];
  return (
    <Card className="illustration-support flex-fill">
      <Card.Body className="p-0 d-flex flex-fill">
        <Row className="row g-0 w-100">
          <Col xs="12">
            <div className="illustration-support-text p-3 m-1">
              <h4 className="illustration-support-text">Need some help?</h4>
              <p className="mb-0">{helpType.description}</p>
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Body style={{ background: "#FFF", color: "#6c757d" }}>
        <FontAwesomeIcon icon={faPhone} className="mx-3" />
        <span className="text-lg">
          <a href={"tel:" + helpType.email}>{helpType.phone}</a>
        </span>
        <hr />
        <FontAwesomeIcon icon={faEnvelope} className="mx-3" />
        <span className="text-lg">
          <a href={"mailto:" + helpType.email}>{helpType.email}</a>
        </span>
        <IsAllowed to="create:tickets">
          <hr />
          <FontAwesomeIcon icon={faTicket} className="mx-3" />
          <span className="text-lg">
            <Link to="/tickets/new">
              <Button className="btn" variant="primary">
                Open a support ticket
              </Button>
            </Link>
          </span>
        </IsAllowed>
      </Card.Body>
      <Card.Footer className="p-1"></Card.Footer>
    </Card>
  );
};

export default GetSupport;

import React, { useState } from "react";
import { Col, Card, Row } from "react-bootstrap";

import { Smartphone, Wifi, Users } from "react-feather";

import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../config";
import { useSelector } from "react-redux";

const Dot11Stats = () => {
  const { userAccessToken } = useAuth();
  const [dot11UserStats, setDot11UserStats] = useState([]);
  const [wlanStats, setWlanStats] = useState([]);
  const bearerToken = `Bearer ${userAccessToken}`;
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.ruckusApiUrl + "wlans/dot11stats", axiosConfig)
      .then(function (response) {
        setDot11UserStats(response.data[0]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    axios
      .get(
        apiConfig.ruckusApiUrl +
          "wlans/stats?operatorid=" +
          selectedLandlord.landlordid,
        axiosConfig
      )
      .then(function (response) {
        setWlanStats(response.data[0]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [bearerToken, selectedLandlord]);
  return (
    <Row className="flex-row px-0 mx-0">
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className="py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{dot11UserStats.unique_logons}</h3>
                <p className="mb-2">Active Wi-Fi Users</p>
                <div className="mb-0">
                  <span className="text-muted text-sm">
                    last updated{" "}
                    {new Date(dot11UserStats.timestamp).toLocaleString(
                      "en-GB",
                      {
                        timeZone: "UTC",
                      }
                    )}
                  </span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <Users className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className="py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{dot11UserStats.unique_devices}</h3>
                <p className="mb-2">Active Wi-Fi Devices</p>
                <div className="mb-0">
                  <span className="text-muted text-sm">
                    last updated{" "}
                    {new Date(dot11UserStats.timestamp).toLocaleString(
                      "en-GB",
                      {
                        timeZone: "UTC",
                      }
                    )}
                  </span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <Smartphone className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{wlanStats?.ap_count}</h3>
                <p className="mb-2">Access Points Broadcasting</p>
                <div className="mb-0">
                  <span className="text-muted text-sm">
                    last updated{" "}
                    {new Date(wlanStats?.timestamp).toLocaleString("en-GB", {
                      timeZone: "UTC",
                    })}
                  </span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <Wifi className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Dot11Stats;

import React, { useState } from "react";
import { Table, Badge, Button } from "react-bootstrap";

const KeyContactsList = ({ data }) => {
  const [keyContactsList, setKeyContactsList] = useState();
  const [showAll, setShowAll] = useState(false);

  React.useEffect(() => {
    if (data.key_contacts) {
      const listItems = data.key_contacts
        .filter((contact) => {
          return contact.type !== "Account Manager";
        })
        .map((contact) => (
          <>
            <strong>{contact.name === " " ? "--" : contact.name}</strong>
            <Table size="sm" className="my-2">
              <tbody>
                <tr>
                  <th>Email</th>
                  <td>{contact.email}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{contact.phone || "--"}</td>
                </tr>
                <tr>
                  <th>Contact Type</th>
                  <td>
                    <Badge bg="primary" className="me-2 btn-gradient">
                      {contact.type}
                    </Badge>
                  </td>
                </tr>
              </tbody>
            </Table>
            <hr />
          </>
        ));
      setKeyContactsList(listItems);
    } else {
      setKeyContactsList(false);
    }
  }, [data]);

  return (
    <div className="custom-scroll">
      {keyContactsList &&
        keyContactsList.slice(0, showAll ? keyContactsList.length : 3)}
      {keyContactsList && keyContactsList.length > 3 && !showAll && (
        <Button onClick={() => setShowAll(true)}>See more</Button>
      )}
    </div>
  );
};

export default KeyContactsList;

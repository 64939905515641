import React from "react";

import SidebarNavList from "./SidebarNavList";

import IsAllowed from "../IsAllowed";

const SidebarNavSection = (props) => {
  const { title, permissionName, pages, className, ...rest } = props;

  return (
    <IsAllowed to={"nav:" + permissionName}>
      <React.Fragment {...rest}>
        {title && <li className="sidebar-header">{title}</li>}
        <SidebarNavList pages={pages} depth={0} />
      </React.Fragment>
    </IsAllowed>
  );
};

export default SidebarNavSection;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  room_id: null,
  vlan_id: null,
  tenant_name: null,
  unit: null,
};

export const tenantNetworkSlice = createSlice({
  name: "tenantnetwork",
  initialState,
  reducers: {
    setTenantNetwork: (state, action) => {
      state.room_id = action.payload.room_id;
      state.vlan_id = action.payload.vlan_id;
      state.tenant_name = action.payload.tenant_name;
      state.unit = action.payload.unit;
      return state;
    },
  },
});

export const { setTenantNetwork } = tenantNetworkSlice.actions;

export default tenantNetworkSlice.reducer;

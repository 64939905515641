import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";

import { quantum } from "ldrs";
quantum.register();

const Stepper = ({
  steps,
  currentPage,
  submissionStatus,
  isSubmissionStep,
}) => {
  return (
    <div className="stepper d-flex flex-column flex-xl-row flex-row-fluid">
      <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
        <div className="stepper-nav d-flex flex-column">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`stepper-item ${
                currentPage > index + 1
                  ? "completed"
                  : currentPage === index + 1
                  ? "current"
                  : ""
              }`}
            >
              <div className="stepper-wrapper">
                <div className="stepper-icon">
                  {index + 1 === steps.length &&
                    isSubmissionStep &&
                    submissionStatus.isLoading && (
                      <l-quantum size="15" color="white" fixedWidth></l-quantum>
                    )}
                  {index + 1 === steps.length &&
                    isSubmissionStep &&
                    !submissionStatus.isLoading &&
                    (submissionStatus.result === "partial" ||
                      submissionStatus.result === "failure") && (
                      <FontAwesomeIcon
                        icon={faExclamation}
                        className="stepper-check"
                      />
                    )}
                  {index + 1 === steps.length &&
                    isSubmissionStep &&
                    !submissionStatus.isLoading &&
                    submissionStatus.result === "success" && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="stepper-check"
                      />
                    )}
                  {index + 1 !== steps.length && (
                    <FontAwesomeIcon icon={faCheck} className="stepper-check" />
                  )}
                  <span className="stepper-number">{index + 1}</span>
                </div>
                <div className="stepper-label">
                  <h4 className="stepper-title">{step.title}</h4>
                  <div className="stepper-desc">{step.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;

import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import { ReactComponent as Logo } from "../../assets/img/Wavenet-Connected-White.svg";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ items }) => {
  const { isOpen } = useSidebar();
  const navigate = useNavigate();

  return (
    <nav className={`sidebar ${!isOpen ? "" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand">
            <Logo onClick={() => navigate("/dashboard/default")} />
          </a>

          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;

import * as Yup from "yup";

import axios from "axios";
import { apiConfig } from "../../../config";

const simulateTask = (taskName) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const isSuccess = Math.random() > 0.1;
      if (isSuccess) {
        resolve(`${taskName} succeeded`); // Indicate which task succeeded
      } else {
        reject(new Error(`${taskName} failed`)); // Indicate which task failed
      }
    }, Math.random() * 9000 + 1000); // Random delay between 1s and 3s
  });
};

const formConfig = {
  name: "customerOrder",
  title: "CREATE NEW CUSTOMER ORDER",
  steps: [
    {
      title: "Customer Details",
      description: "Select which customer to move-in",
      longDescription:
        "Choose an existing customer or a registered prospect for this order. If you need to register a new customer, please use the customer registration form.",
      stepType: "input",
      fields: [
        {
          name: "registered_customer",
          label: "Are you ordering for an existing Wavenet customer?",
          confirmLabel: "Existing Wavenet Customer",
          type: "radiotile",
          options: [
            {
              label: "Existing Customer",
              description:
                "I am ordering for an existing Wavenet customer with an XLW account number.",
              value: "true",
              name: "registered_customer",
            },
            {
              label: "New Customer",
              description:
                "I am ordering for a new Wavenet prospect that has been registered in Wavenet's CRM.",
              value: "false",
              name: "registered_customer",
            },
          ],
          validation: null,
        },
        {
          name: "customer",
          label: "Find Customer",
          confirmLabel: "Customer",
          type: "select",
          options: [
            {
              label: "Wayne Enterpises",
              value: "CRM00001",
              name: "customer",
            },
            {
              label: "Oscorp",
              value: "CRM00002",
              name: "customer",
            },
            {
              label: "Daily Planet",
              value: "CRM00003",
              name: "customer",
            },
            {
              label: "LexCorp",
              value: "CRM00004",
              name: "customer",
            },
            {
              label: "Gringotts Wizarding Bank",
              value: "CRM00005",
              name: "customer",
            },
            {
              label: "Xavier's School for Gifted Youngsters",
              value: "CRM00006",
              name: "customer",
            },
            {
              label: "Axme Corporation",
              value: "CRM00007",
              name: "customer",
            },
            {
              label: "Hogwarts School of Witchcraft and Wizardry",
              value: "CRM00008",
              name: "customer",
            },
            {
              label: "Wakanda Design Group",
              value: "CRM00009",
              name: "customer",
            },
            {
              label: "Stark Industries",
              value: "CRM00010",
              name: "customer",
            },
          ],
          validation: Yup.string().required("Customer is required"),
          condition: (formValues) => formValues.registered_customer === "true",
        },
        {
          name: "customer",
          label: "Find Prospect",
          confirmLabel: "Prospect",
          type: "select",
          options: [
            {
              label: "Layer8 Technologies Ltd",
              value: "CRM00001",
              name: "customer",
            },
            {
              label: "Microsoft Plc",
              value: "CRM00002",
              name: "customer",
            },
            {
              label: "Alphabet LLC",
              value: "CRM00003",
              name: "customer",
            },
            {
              label: "Tesla, Inc.",
              value: "CRM00004",
              name: "customer",
            },
          ],
          validation: Yup.string().required("Customer is required"),
          condition: (formValues) => formValues.registered_customer === "false",
        },
      ],
      validationSchema: Yup.object().shape({
        customer: Yup.object()
          .shape({
            value: Yup.string().required("Customer selection is required"),
          })
          .required("Customer is required"),
      }),
    },
    {
      title: "Building & Unit",
      description: "Choose a building and room",
      stepType: "input",
      fields: [
        {
          name: "building",
          label: "Select Building",
          confirmLabel: "Building",
          type: "select",
          options: [
            {
              label: "Wayne Manor",
              value: "muWM",
              name: "building",
            },
          ],
          validation: Yup.string().required("Building is required"),
        },
        {
          name: "unit",
          label: "Select Unit",
          confirmLabel: "Unit",
          type: "select",
          options: [
            {
              label: "Unit 101",
              value: "UNIT101",
              name: "unit",
            },
            {
              label: "Unit 102",
              value: "UNIT102",
              name: "unit",
            },
            {
              label: "Unit 103",
              value: "UNIT103",
              name: "unit",
            },
            {
              label: "Unit 104",
              value: "UNIT104",
              name: "unit",
            },
            {
              label: "Unit 105",
              value: "UNIT105",
              name: "unit",
            },
          ],
          validation: Yup.string().required("Unit is required"),
        },
      ],
      validationSchema: Yup.object().shape({
        building: Yup.object().shape({
          value: Yup.string().required("Building selection is required"),
        }),
        unit: Yup.object().shape({
          value: Yup.string().required("Unit selection is required"),
        }),
      }),
    },
    {
      title: "Move-In Date",
      description: "Choose the move-in date",
      longDescription:
        "Tell us what date the customer will be moving into this unit. We'll use this to schedule the service provisioning.",
      stepType: "input",
      fields: [
        {
          name: "moveInDate",
          label: "Move-In Date",
          confirmLabel: "Move-In Date",
          type: "date",
          validation: null,
        },
      ],
      validationSchema: Yup.object().shape({
        moveInDate: Yup.date().required("Move-in date is required"),
      }),
    },
    {
      title: "Commercial Model",
      description: "Select the commercial model for this order",
      longDescription:
        "Select the commercial model that has been agreed with the prospective tenant for this order.",
      stepType: "input",
      fields: [
        {
          name: "commercialModel",
          label: "Available Commercial Models",
          confirmLabel: "Commercial Model",
          type: "select",
          options: [
            {
              label: "Workspace Inclusive",
              value: "WSG-INCL-01",
              name: "commercialModel",
            },
            {
              label: "Workspace Exclusive",
              value: "WSG-EXCL-01",
              name: "commercialModel",
            },
            {
              label: "Workspace Reduced",
              value: "WSG-REDC-01",
              name: "commercialModel",
            },
          ],
          validation: null,
        },
      ],
      validationSchema: Yup.object().shape({
        commercialModel: Yup.object().shape({
          value: Yup.string().required(
            "Commercial model selection is required"
          ),
        }),
      }),
    },
    {
      title: "Network Services Products",
      description: "Specify products to be included in this order",
      longDescription: "Add network services products to the order below.",
      stepType: "input",
      fields: [
        // {
        //   name: "OSIA",
        //   title: "Open Secure Internet Access",
        //   label: "Choose bandwidth allowance for customer",
        //   confirmLabel: "Bandwidth allowance",
        //   type: "range",
        //   options: {
        //     step: 1,
        //     min: 1,
        //     max: 100,
        //     unit: "Workstations",
        //     marks: [1, 50, 100],
        //   },
        //   validation: null,
        // },
        {
          name: "networkServiceSelection",
          title: "Choose a network service",
          label: "Choose a network service",
          confirmLabel: "Network Service",
          type: "select",
          options: [
            {
              label: "OSIA",
              value: "OSIA",
              name: "networkServiceSelection",
            },
          ],
          validation: null,
        },
        {
          name: "vlanSelection",
          title: "Customer VLAN Type",
          label: "Customer VLAN selection",
          confirmLabel: "VLAN for customer network",
          type: "select",
          options: [
            {
              label: "Normal",
              value: "Normal",
              name: "vlanSelection",
            },
            {
              label: "X-Connect",
              value: "XConnect",
              name: "vlanSelection",
            },
          ],
          validation: null,
          condition: (formValues) =>
            formValues.networkServiceSelection.value === "OSIA",
        },
        {
          name: "staticIpAddresses",
          title: "Public Static IP Addresses",
          label: "Customer requires public static IPs",
          confirmLabel: "Static IPs Required",
          // infoBox:
          //   "Select this option if you want to automatically provision the customer IT network for the unit.",
          type: "switch",
          validation: null,
          condition: (formValues) =>
            formValues.networkServiceSelection.value === "OSIA",
        },
        {
          name: "numberStaticIps",
          title: "Number of static IP addresses",
          label: "Number of static IP addresses",
          // infoBox:
          //   "If the customer needs more than 8 static IP addresses, please contact sales.",
          confirmLabel: "Number of static IP addresses",
          type: "range",
          options: {
            step: 1,
            min: 0,
            max: 8,
            unit: "",
            marks: [0, 1, 2, 3, 4, 5, 6, 7, 8],
          },
          validation: null,
          condition: (formValues) => formValues.staticIpAddresses === true,
        },
        {
          name: "provisionWifiUsers",
          title: "Wireless Network User Setup",
          infoBox:
            "Select this option if you want to automatically create Wi-Fi credentials for this customer's key contacts and send the subsequent onboarding email.",
          label: "Provision Wi-Fi Credentials",
          confirmLabel: "Provision wireless network",
          type: "switch",
          validation: null,
        },
        // {
        //   name: "sendWifiCredentials",
        //   title: "Credential delivery",
        //   label: "Send wi-fi onboarding email to customer?",
        //   confirmLabel: "Send credentials to customer",
        //   type: "checkbox",
        //   validation: null,
        //   condition: (formValues) => formValues.provisionWifiUsers === true,
        // },
      ],
      validationSchema: null,
    },
    // {
    //   title: "Calculate Order Pricing",
    //   confirmationStep: true,
    //   description: "Calculate pricing based on the order details",
    //   stepType: "review",
    //   fields: [
    //     {
    //       name: "confirm",
    //       label: "Confirm that the below details are correct",
    //       type: "confirm",
    //       validation: null,
    //     },
    //   ],
    //   validationSchema: null,
    // },
    {
      title: "Calculate Order Pricing",
      confirmationStep: true,
      description: "Calculate pricing based on the order details",
      longDescription:
        "Calculating the pricing summary based on the order details.",
      stepType: "externalQuery__pricing",
      fields: [],
      queryHandler: (
        formData,
        userAccessToken,
        setLoadingExternalQuery,
        setExternalQueryResults,
        setExternalQueryError,
        setMyFormData
      ) => {
        setLoadingExternalQuery(true);

        axios
          .post(`${apiConfig.hubspotApiUrl}orders/price_summary`, formData, {
            headers: {
              Authorization: `Bearer ${userAccessToken}`,
            },
          })
          .then((response) => {
            setMyFormData({
              ...formData,
              calculatedPricing: response.data.pricing,
            });
            setExternalQueryError("");
            setExternalQueryResults(response.data.pricing.lineItems);
            setLoadingExternalQuery(false);
          })
          .catch((error) => {
            setMyFormData({
              ...formData,
              calculatedPricing: null,
            });
            console.log(error);
            setExternalQueryResults([]);
            setExternalQueryError(
              error?.response?.data?.error
                ? error?.response?.data?.error
                : JSON.stringify(error)
            );
            setLoadingExternalQuery(false);
            console.log(error.response);
          });
        return null;
      },
      validationSchema: null,
    },
    {
      title: "Finish",
      description: "Create the order in Wavenet's systems",
      stepType: "process",
      confirmationStep: true,
      fields: [],
    },
  ],
  handleSubmit: async (
    formData,
    { setSubmissionStatus, token, updateTaskStatus }
  ) => {
    console.log("Attempting to submit form data:", formData);

    // Initialize an empty array for tasks to start with a clean state
    setSubmissionStatus({ isLoading: true, tasks: [] });

    const tasks = ["Register deal in CRM"]; // Define your tasks
    if (formData.commercialModel.value !== "WSG-INCL-01") {
      tasks.push("Generate DealHub Quotation");
    }
    tasks.forEach((taskName) => {
      // Update the status to loading for each task initially
      updateTaskStatus(taskName, "loading", null);

      // Simulate each task and update the status accordingly
      simulateTask(taskName)
        .then((result) => {
          updateTaskStatus(taskName, "succeeded", result);
        })
        .catch((error) => {
          updateTaskStatus(taskName, "failed", error.message);
        });
    });
  },
};

export default formConfig;

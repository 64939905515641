import React from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row, Breadcrumb } from "react-bootstrap";

import GetSupport from "../../components/GetSupport";
import OffcanvasHelp from "../../components/OffcanvasHelp";
import EditCustomerRegistrationForm from "./EditCustomerRegistrationForm";

import { useNavigate, useParams } from "react-router-dom";

const EditCustomerRegistration = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  return (
    <React.Fragment>
      <Helmet title="Customer Registrations" />
      <Container fluid className="p-0">
        <Breadcrumb className="float-end mt-0">
          <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate("/customers/registrations")}>
            Customer Registrations
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            Editing Registration Form #{id}
          </Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h3 mb-3">
          Customer Registrations
          <OffcanvasHelp id="customers" name="Customers" scroll backdrop />
        </h1>
        <div className="clearfix"></div>
        <Row>
          <Col xl="9">
            <EditCustomerRegistrationForm />
          </Col>
          <Col xl="3">
            <GetSupport type="commercial" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default EditCustomerRegistration;

import { Link } from "react-router-dom";

const tableColumns = [
  {
    Header: "Unit Number",
    accessor: "room_number",
  },
  {
    Header: "Current Tenant",
    accessor: "tenant",
    Cell: (e) => (
      <div className="">
        <Link to={"/customers/profile/" + e.row.original.account_number}>
          {e.value}
        </Link>
      </div>
    ),
  },
  {
    Header: "Account Number",
    accessor: "account_number",
  },
  {
    Header: "Floor",
    accessor: "floor",
  },
  {
    Header: "Size (sq ft)",
    accessor: "area_squarefeet",
  },
];

export { tableColumns };

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lng: 0,
  lat: 0,
};

export const coordinatesSlice = createSlice({
  name: "coordinates",
  initialState,
  reducers: {
    setCoordinates: (state, action) => {
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
      return state;
    },
  },
});

export const { setCoordinates } = coordinatesSlice.actions;

export default coordinatesSlice.reducer;

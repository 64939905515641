import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Col, Row, Card, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import NotificationsTable from "./Notifications";
import GetSupport from "../../components/GetSupport";

import useAuth from "../../hooks/useAuth";

const ManageNotifications = () => {
  const { user, isAuthenticated } = useAuth();
  const [mobileIsSet, setMobileIsSet] = React.useState(false);

  React.useEffect(() => {
    if (isAuthenticated && user.mobile) {
      setMobileIsSet(true);
    }
  }, [isAuthenticated, user.mobile]);

  return (
    <>
      <Helmet title="Notifications" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Manage Notifications</h1>
        <Row>
          <Col md="9" xl="9">
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">
                  Notification Subscriptions
                </Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <p>
                  You can choose to receive notifications from Gateway when
                  certain events occur. Notifications will be displayed in the
                  app, and optionally can be sent to you via email or SMS.
                </p>
                {!mobileIsSet && (
                  <Alert variant="info">
                    <div className="alert-icon">
                      <FontAwesomeIcon icon={faInfoCircle} fixedWidth />
                    </div>
                    <div className="alert-message">
                      You have not set your mobile phone number, you won't
                      receive SMS alerts.{" "}
                      <Link
                        to="/profile/edit/"
                        style={{ color: "lightslategrey" }}
                      >
                        Add your mobile number now.
                      </Link>
                    </div>
                  </Alert>
                )}
                <NotificationsTable />
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3">
            <GetSupport type="support" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ManageNotifications;

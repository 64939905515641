import React, { useEffect, useState } from "react";

import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Mail, Sliders, Smartphone, Users } from "react-feather";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../../constants";

import useTheme from "../../hooks/useTheme";
import useSidebar from "../../hooks/useSidebar";
import useLayout from "../../hooks/useLayout";

import { ReactComponent as Logo } from "../../assets/img/Wavenet-Connected-White.svg";

import screenshotWavenetGateway from "../../assets/img/screenshots/wavenet-gateway-screenshot.png";

const Navigation = () => (
  <Navbar expand="md" className="landing-navbar">
    <Container>
      <Navbar.Brand className="landing-brand" href="/">
        <Logo /> Wavenet Gateway{" "}
      </Navbar.Brand>
      <Nav className="ms-auto" navbar>
        <Nav.Item className="d-none d-md-inline-block">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-bottom">
                Closed Beta - We'll let you know when you can access
              </Tooltip>
            }
          >
            <Nav.Link href="#" active className="text-lg px-lg-3">
              Request Access
            </Nav.Link>
          </OverlayTrigger>
        </Nav.Item>
      </Nav>
      <Button
        href="/dashboard/default"
        target="_top"
        rel="noopener noreferrer"
        variant="primary"
        className="ms-2"
        size="lg"
      >
        Sign-In Now
      </Button>
    </Container>
  </Navbar>
);

const Intro = () => (
  <section className="landing-intro landing-bg pt-5 pt-lg-6 pb-5 pb-lg-7">
    <Container className="landing-intro-content">
      <Row className="align-items-center">
        <Col lg="5" className="mx-auto">
          <h1 className="my-4">
            Your Gateway to observing & managing your Wavenet Connected
            services.
          </h1>

          <p className="text-lg">
            The Gateway from Wavenet Connected allows customers to manage every
            aspect of their services including billing, support tickets and
            network management.
          </p>

          <div className="my-4">
            <a
              href="/dashboard/default"
              className="btn btn-primary btn-lg me-2"
            >
              Sign-In Now
            </a>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-bottom">
                  Closed Beta - We'll let you know when you can access
                </Tooltip>
              }
            >
              <a // eslint-disable-line
                href="#"
                rel="noreferrer"
                className="btn btn-outline-dark btn-lg me-1"
              >
                Request Access
              </a>
            </OverlayTrigger>
          </div>
        </Col>
        <Col lg="7" className="d-none d-lg-flex mx-auto text-center">
          <div className="landing-intro-screenshot pb-3">
            <img
              src={screenshotWavenetGateway}
              alt="Dark/Light Bootstrap React Admin Template"
              className="img-fluid"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

const Features = () => (
  <section className="py-6">
    <Container>
      <Row>
        <Col md="10" className="mx-auto text-center">
          <div className="mb-5">
            <h2 className="h1">Features you'll love</h2>
            <p className="text-muted text-lg">
              The Wavenet Connected Gateway was created with our customers in
              mind.
            </p>
          </div>

          <Row className="text-start">
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Sliders />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Service Management</h4>
                  <p className="fs-lg">
                    Configure your Wavenet Connected services such as Wi-Fi
                    credentials.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Smartphone />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Fully Responsive</h4>
                  <p className="fs-lg">
                    With mobile, tablet & desktop support it doesn't matter what
                    device you're using. Gateway is responsive in all browsers.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Mail />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Support Tickets</h4>
                  <p className="fs-lg">
                    View, manage and update your support tickets in one easy to
                    use interface.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex py-3">
                <div className="landing-feature">
                  <Users />
                </div>
                <div className="flex-grow-1">
                  <h4 className="mt-0">Secure Role-Based Access Control</h4>
                  <p className="fs-lg">
                    The Wavenet Gateway allows you to give the right level of
                    access to those people in your organisation that need it.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
);

const Faq = () => {
  const [activeKey, setActiveKey] = useState("0");

  return (
    <section className="bg-white py-6">
      <Container>
        <div className="mb-5 text-center">
          <h2 className="h1">Frequently Asked Questions</h2>
          <p className="text-muted fs-lg">
            Here are some of the answers you might be looking for.
          </p>
        </div>

        <Row>
          <Col md={9} lg={8} className="mx-auto">
            <Accordion activeKey={activeKey}>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("0")}
                >
                  <h6 className="mb-0">
                    Who is the Wavenet Connected Gateway for?
                  </h6>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="py-3">
                    The Wavenet Connected Gateway is for all Wavenet Connected
                    customers, from building operators to end-users.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("1")}
                >
                  <h6 className="mb-0">How can I request access?</h6>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="py-3">
                    {/*eslint-disable */}
                    We're currently in a closed beta testing phase. Your account
                    manager will contact you when you are able to access the
                    Gateway upon launch.
                    {/*eslint-enable */}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="border mb-3">
                <Card.Header
                  className="cursor-pointer"
                  onClick={() => setActiveKey("2")}
                >
                  <h6 className="mb-0">
                    How often do you update Gateway features?
                  </h6>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="py-3">
                    {/*eslint-disable */}
                    We're always listening to feedback from our customers about
                    what they would like to see in Gateway. If you are missing a
                    feature then please let us know by submitting a feedback
                    response{" "}
                    <a href="#" rel="noopener noreferrer" target="_top">
                      here
                    </a>
                    .{/*eslint-enable */}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const Footer = () => (
  <section className="landing-footer py-6">
    <Container className="text-center landing-footer-container">
      <Row>
        <Col md="9" lg="8" xl="6" className="mx-auto">
          <h2 className="h1 mb-3">
            Join over 3,500 customers who are already using the Wavenet
            Connected Gateway.
          </h2>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-bottom">
                Closed Beta - We'll let you know when you can access
              </Tooltip>
            }
          >
            <Button
              variant="dark"
              size="lg"
              href="#"
              target="_top"
              rel="noopener noreferrer"
              className="mt-n1"
            >
              Request Access
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
    </Container>
  </section>
);

const Landing = () => {
  const { setTheme } = useTheme();
  const { setPosition, setBehavior } = useSidebar();
  const { setLayout } = useLayout();

  useEffect(() => {
    setTheme(THEME.DEFAULT);
    setPosition(SIDEBAR_POSITION.LEFT);
    setBehavior(SIDEBAR_BEHAVIOR.STICKY);
    setLayout(LAYOUT.FLUID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <Intro />
      <Features />
      <Faq />
      <Footer />
    </React.Fragment>
  );
};

export default Landing;

import React, { useState, useContext } from "react";
import { Tab, Button, Card, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { apiConfig } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import NotyfContext from "../../../contexts/NotyfContext";
import IsAllowed from "../../../components/IsAllowed";
import NotAuthorised from "../../../components/NotAuthorised";
import axios from "axios";
import { useSelector } from "react-redux";

const Dot11Admin = () => {
  const { userAccessToken } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  const [customerOptions, setCustomerOptions] = useState([]);
  const [isLoadingCustomerOptions, setIsLoadingCustomerOptions] =
    useState(true);
  const [excellId, setExcellId] = useState(null);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    setIsLoadingCustomerOptions(true);
    setExcellId(null);
    if (selectedLandlord.landlordid) {
      axios
        .get(
          apiConfig.symbillApiUrl +
            "newsymbillapi/byCtidList/" +
            selectedLandlord.landlordid,
          axiosConfig
        )
        .then(function (response) {
          let customersList = [];
          response.data.forEach((customer) => {
            customersList.push({
              value: customer.accountNumber,
              label: customer.companyName + " (" + customer.accountNumber + ")",
            });
          });
          //setLoading(false);
          setCustomerOptions(customersList);
          setIsLoadingCustomerOptions(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setIsLoadingCustomerOptions(false);
        });
    } else {
      setCustomerOptions([]);
      setIsLoadingCustomerOptions(false);
    }
  }, [selectedLandlord, bearerToken]);

  const notyf = useContext(NotyfContext);

  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  // Handle rebuild VLANS submit
  const handleRebuildVlansSubmit = (event) => {
    event.preventDefault();
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .patch(
        `${apiConfig.dot11ApiUrl}users/account/${excellId}/rebuildVlans`,
        null,
        axiosConfig
      )
      .then(function (response) {
        if (response.status === 200) {
          // Launch notyf based on the API response
          notyf.open({
            type,
            message: response.data.message,
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
          setExcellId(null);
        } else {
          // Launch notyf based on the API response
          notyf.open({
            type: "warning",
            message: response.data.message || "Unknown error",
            duration,
            ripple,
            dismissible,
            position: {
              x: positionX,
              y: positionY,
            },
          });
          setExcellId(null);
        }
      })
      .catch(function (error) {
        console.log(error);
        // handle error
        notyf.open({
          type: "warning",
          message: error.response.data.message || "Unknown error",
          duration,
          ripple,
          dismissible,
          position: {
            x: positionX,
            y: positionY,
          },
        });
        setExcellId(null);
      });
  };

  return (
    <>
      <Tab.Pane eventKey="#Dot11Administration">
        <IsAllowed to="view:systemsettings:users" fallback={<NotAuthorised />}>
          <Card.Header>
            <Card.Title className="mb-0">Dot11 Administration</Card.Title>
          </Card.Header>
          <Card.Body className="py-0">
            <Form onSubmit={handleRebuildVlansSubmit}>
              <Row>
                <Form.Group className="mb-3 col-md-5">
                  <Form.Label>Rebuild VLANs for account</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={
                      isLoadingCustomerOptions
                        ? [{ value: "", label: "Loading..." }]
                        : customerOptions
                    }
                    isDisabled={
                      isLoadingCustomerOptions || customerOptions.length === 0
                    }
                    onChange={(e) => setExcellId(e.value)}
                    value={
                      excellId
                        ? customerOptions.find(
                            (option) => option.value === excellId
                          )
                        : null
                    }
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-3 col-md-2">
                  <Button className="btn btn-light btn-lg" type="submit">
                    Rebuild VLANs Now
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </Card.Body>
        </IsAllowed>
      </Tab.Pane>
    </>
  );
};

export default Dot11Admin;

import React from "react";

import { Card, ListGroup } from "react-bootstrap";

import { Server } from "react-feather";

const DeviceSelector = ({ data, selectedDevice, setSelectedDevice }) => {
  const handleDeviceClick = (device) => {
    setSelectedDevice({
      deviceName:
        device.deviceInfo.device_name || device.deviceInfo.device_hostname,
      deviceInventoryId: device.deviceInfo.id,
      inventorySource: "logicmonitor",
    });
  };

  return (
    <Card className="flex-fill">
      <Card.Header>
        <Card.Title className="mb-0">Select Device</Card.Title>
        <h5 className="mt-3 mb-0">
          Select a device below to view device stats
        </h5>
      </Card.Header>
      <ListGroup
        variant="flush"
        style={{ maxHeight: 350, overflowY: "scroll" }}
      >
        {data.map((device, index) => (
          <ListGroup.Item
            key={index}
            action
            onClick={() => handleDeviceClick(device)}
            active={selectedDevice.deviceInventoryId === device.deviceInfo.id}
            style={{ cursor: "pointer" }}
          >
            <Server className="pe-1 me-2" />
            {device.deviceInfo.device_name || device.deviceInfo.device_hostname}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  );

  // let devices = data;
  // let device_list = [];
  // devices.forEach((device, index) => {
  //   device_list.push(
  //     <ListGroup.Item key={"#" + index} tag="a" href={"#" + (index + 1)} action>
  //       <Server className="pe-1 me-2" />
  //       {device.deviceInfo.device_name || device.deviceInfo.device_hostname}
  //     </ListGroup.Item>
  //   );
  // });
  // return (
  //   <Card className="flex-fill">
  //     <Card.Header>
  //       <Card.Title className="mb-0">Select Device</Card.Title>
  //       <h5 className="mt-3 mb-0">
  //         Select a device below to view device stats
  //       </h5>
  //     </Card.Header>
  //     <ListGroup
  //       variant="flush"
  //       style={{ maxHeight: 350, overflowY: "scroll" }}
  //     >
  //       {device_list}
  //     </ListGroup>
  //   </Card>
  // );
};

export default DeviceSelector;

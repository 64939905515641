import React from "react";

import { Col, Row, Table, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortUp,
  faSortDown,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { useTable, useSortBy, usePagination } from "react-table";

const ColumnSortingTable = ({
  columns,
  data,
  hiddenColumns = [],
  onRowClick,
  selectedDevice,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: hiddenColumns,
        sortBy: [
          {
            id: "deviceInfo.device_role",
            desc: false,
          },
        ],
      },
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <React.Fragment>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} className="ms-2" />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps({ style: { borderTopStyle: "hidden" } })}>
          {(page.length > 0 &&
            page.map((row, i) => {
              prepareRow(row);
              const isSelected =
                row.original.deviceInfo.id === selectedDevice.deviceInventoryId;
              return (
                <tr
                  {...row.getRowProps({
                    onClick: () => onRowClick(row),
                    style: {
                      backgroundColor: isSelected ? "#ff5f00" : "",
                      cursor: "pointer",
                      color: isSelected ? "#FFF" : "inherit",
                    },
                  })}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })) || (
            <tr>
              <td colSpan={headerGroups[0].headers.length}>
                <span className="d-block mx-auto p-3 text-center">
                  None found
                </span>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Row>
        <Col md="6" className="text-sm">
          <span className="mx-2">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          {/* <span className="ms-3 me-2">Show:</span>
          <Form.Select
            className="d-inline-block w-auto"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Form.Select> */}
        </Col>
        <Col md="6">
          <Pagination className="float-end">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ColumnSortingTable;

import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";

import GroupsTable from "./Groups";
import GetSupport from "../../components/GetSupport";

const ManageGroups = () => {
  return (
    <>
      <Helmet title="Manage Notification Groups" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Manage Notification Groups</h1>
        <Row>
          <Col md="9" xl="9">
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">Notification Groups</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <p>
                  You can create Notification Groups to add to notification
                  subscriptions, resulting in a distribution list of users who
                  will receive notifications.
                </p>
                <GroupsTable />
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3">
            <GetSupport type="support" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ManageGroups;

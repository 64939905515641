import React from "react";
import { useSelector } from "react-redux";

const IsDisabledForLandlordId = ({
  children,
  disabledForLandlordIds = [],
  enabledForLandlordIds = [],
}) => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);

  if (!selectedLandlord) {
    return null; // No selected landlord, so nothing to display
  }

  const selectedLandlordId = selectedLandlord.landlordid;

  // Check if selectedLandlordId is in disabledForLandlordIds
  if (disabledForLandlordIds.includes(selectedLandlordId)) {
    return null; // Selected landlord is disabled, so nothing to display
  }

  // Check if enabledForLandlordIds has items
  if (enabledForLandlordIds.length > 0) {
    // Check if selectedLandlordId is in enabledForLandlordIds
    if (!enabledForLandlordIds.includes(selectedLandlordId)) {
      return null; // Selected landlord is not enabled, so nothing to display
    }
  }

  // If none of the conditions above are met, display the children
  return <>{children}</>;
};

export default IsDisabledForLandlordId;

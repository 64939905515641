import React from "react";
import Chart from "react-apexcharts";

import usePalette from "../../hooks/usePalette";

import { Card } from "react-bootstrap";

const LineChart = (data) => {
  const palette = usePalette();
  let rxData = data.data.rxData;
  let txData = data.data.txData;
  let availability30d = data.data.uptime;
  let timestamps = data.data.dates;
  let percentile95th = data.data.percentile95th;
  const series = [
    {
      name: "Bandwidth Usage (Upload)",
      type: "area",
      data: txData,
    },
    {
      name: "Bandwidth Usage (Download)",
      type: "area",
      data: rxData,
    },
    {
      name: "Core Router Ping",
      type: "line",
      data: availability30d,
    },
  ];

  const options = {
    colors: [
      palette.lighterorange,
      palette.connectedorange,
      palette.publicsectorgreen,
    ],
    dataLabels: {
      enabled: false,
    },
    chart: {
      height: 350,
      type: "area",
    },
    stroke: {
      curve: ["smooth", "smooth", "stepline"],
      width: 1,
    },
    fill: {
      type: "solid",
      opacity: [0.35, 0.35, 1],
    },
    markers: {
      size: 0,
    },
    xaxis: {
      labels: {
        rotate: 0,
      },
      type: "datetime",
      categories: timestamps,
    },
    yaxis: [
      {
        seriesName: "Bandwidth Usage (Download)",
        title: {
          text: "Bandwidth Usage (Mbps)",
        },
      },
      {
        seriesName: "Bandwidth Usage (Upload)",
        show: false,
      },
      {
        opposite: true,
        title: {
          text: "Core Router Ping (%)",
        },
        min: 0,
        max: 100,
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: function (y) {
            return y + "Mbps";
          },
        },
        {
          formatter: function (y) {
            return y + "Mbps";
          },
        },
        {
          formatter: function (y) {
            return y + "%";
          },
        },
      ],
      x: {
        format: "dd/MM/yy HH:mm",
      },
      followCursor: true,
    },
    annotations: {
      yaxis: [
        {
          y: percentile95th,
          borderColor: palette.connectedorange,
          label: {
            borderColor: palette.connectedorange,
            style: {
              color: "#fff",
              background: palette.connectedorange,
            },
            text: "95th Percentile @ " + percentile95th + "Mbps",
          },
        },
      ],
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">
          Network Availability &amp; Usage
        </Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Chart options={options} series={series} type="area" height="350" />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default LineChart;

import React from "react";

const NotFound = ({ message }) => {
  return (
    <React.Fragment>
      <div className="text-center p-4">
        <p className="h1">Couldn't retrieve data</p>
        <p className="h2 fw-normal mt-3 mb-4">
          We couldn't retrieve the data for this module. If this continues to
          happen please contact support.
        </p>
        <p>{message}</p>
      </div>
    </React.Fragment>
  );
};

export default NotFound;

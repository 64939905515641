import React, { useState, useEffect } from "react";

import { Accordion } from "rsuite";

import { Row, Col } from "react-bootstrap";

const NetworkAutomationHostResult = ({ selectedDevice, workflowData }) => {
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    setActiveKey(null);
  }, [selectedDevice]);
  if (selectedDevice.id === null) {
    return <div className="text-center">Select a host to view results</div>;
  }

  const hostInformation = workflowData.manifest.hosts.filter((host) => {
    return host.hostname === selectedDevice.hostname;
  });

  const steps = workflowData.result[selectedDevice.id].host_results.map(
    (stepResult, index) => {
      // if (stepResult.step.step_name === "get_config") {
      //   return null;
      // }
      return (
        <Accordion.Panel
          eventKey={index}
          header={index + 1 + ". " + stepResult.step.step_name}
        >
          <pre style={{ height: "250px", whiteSpace: "pre-wrap" }}>
            {stepResult.message}
          </pre>
        </Accordion.Panel>
      );
    }
  );

  steps.push(
    <Accordion.Panel
      eventKey={steps.length + 1}
      header={"Post-Completion Config Diff"}
    >
      <pre style={{ height: "250px", whiteSpace: "pre-wrap" }}>
        {workflowData.result[selectedDevice.id].diff
          ? workflowData.result[selectedDevice.id].diff
          : "No Diff"}
      </pre>
    </Accordion.Panel>
  );

  return (
    <>
      <Row className="mb-1 p-3">
        <h4 className="mb-4">Device Info</h4>
        <Col md={3} className="border-end text-center">
          <h5>Hostname</h5> {hostInformation[0].hostname}
        </Col>
        <Col md={3} className="border-end text-center">
          <h5>IP Address</h5> {hostInformation[0].ip}
        </Col>
        <Col md={3} className="border-end text-center">
          <h5>Device Category</h5> {hostInformation[0].type}
        </Col>
        <Col md={3} className="text-center">
          <h5>Platform</h5> {hostInformation[0].platform}
        </Col>
      </Row>
      <Row className="p-3">
        <h4 className="mb-3">Workflow Step Logs</h4>
        <Accordion activeKey={activeKey} bordered onSelect={setActiveKey}>
          {steps}
        </Accordion>
      </Row>
    </>
  );
};
export default NetworkAutomationHostResult;

import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import axios from "axios";

import { apiConfig } from "../../config";

function Dot11ResetPassword(data) {
  const [success, setSuccess] = useState(false);

  // Password validation schema
  const passwordSchema = Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password must contain an uppercase letter")
    .matches(/[a-z]/, "Password must contain a lowercase letter")
    .matches(/[0-9]/, "Password must contain a digit")
    .matches(/^\S*$/, "Password must not contain spaces")
    .required("Password is required");

  // Form validation schema
  const validationSchema = Yup.object().shape({
    password: passwordSchema,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  // Handle submit function
  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const response = await axios.patch(
        `${apiConfig.dot11ApiUrl}public/process`,
        {
          newPassword: values.password,
          randomid: data.token,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
      } else {
        throw new Error("An error occurred while setting the password");
      }
    } catch (error) {
      const message = error.response.data.message || "Something went wrong";

      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) =>
        success ? (
          <div>
            <Alert variant="success" className="p-2">
              Password set successfully. You can close this window.
            </Alert>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                label="Password"
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                value={values.confirmPassword}
                isInvalid={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.confirmPassword && (
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div className="text-center mt-3">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={
                  !isValid ||
                  isSubmitting ||
                  !(touched.password || touched.confirmPassword)
                }
              >
                {data.isNewCustomer ? "Set" : "Reset"} password
              </Button>
            </div>
          </Form>
        )
      }
    </Formik>
  );
}

export default Dot11ResetPassword;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Alert,
  Card,
  Badge,
  // ButtonGroup,
  Row,
  Col,
  // Dropdown,
  Button,
  Table,
} from "react-bootstrap";
import {
  InlineEdit,
  Input,
  Form,
  Stack,
  Loader,
  Message,
  // SelectPicker,
  CheckPicker,
  Text,
  Tooltip,
  Whisper,
} from "rsuite";
// import { PhoneInput } from "react-international-phone";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { apiConfig } from "../../config";
import axios from "axios";
import NotFound from "../../components/NotFound";
import IsAllowed from "../../components/IsAllowed";
import moment from "moment";

const statusColourMap = {
  draft: "secondary",
  pending: "primary",
  complete: "success",
};

const Field = ({
  label,
  as: Component,
  value,
  placeholder,
  onChange,
  onSave,
  showControls = true,
  disabled,
  ...rest
}) => {
  return (
    <Stack direction="row" spacing={15}>
      <label
        style={{
          width: 200,
          display: "inline-block",
          color: "var(--rs-text-secondary)",
        }}
      >
        {label}
      </label>
      <InlineEdit
        placeholder={placeholder ? placeholder : "--"}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onSave={onSave}
        showControls={showControls}
      >
        <Component style={{ width: 400 }} value={value} {...rest} />
      </InlineEdit>
    </Stack>
  );
};

const tooltip = (text) => {
  return <Tooltip>{text}</Tooltip>;
};

const EditCustomerRegistrationForm = () => {
  // Auth
  const { userAccessToken, isAuthenticated } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const { id } = useParams();

  // State
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [, setFormData] = useState({});
  const [editFormData, setEditFormData] = useState({});
  const [approvalMessage, setApprovalMessage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);

  // Effects
  useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (isAuthenticated) {
      axios
        .get(`${apiConfig.hubspotApiUrl}companies/signups/${id}`, axiosConfig)
        .then((response) => {
          const data = response.data[0];
          setFormData(data);
          setEditFormData({
            status: data.status,
            assigned_to: data.assigned_to,
            hubspot_id: data.form_data.hubspot_id || "",
            company_name: data.form_data.company_name || "",
            company_domain: data.form_data.company_domain || "",
            company_number: data.form_data.company_number || "",
            company_phone: data.form_data.company_phone || "",
            address1: data.form_data.address1 || "",
            address2: data.form_data.address2 || "",
            postcode: data.form_data.postcode || "",
            company_contacts: data.form_data.company_contacts || [],
            new_company_contacts: data.form_data.new_company_contacts || [],
            approval_message: data.form_data.approval_message || "",
            approval_user: data.form_data.approval_user || "",
            // Add other fields as necessary
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setNoData(true);
          setLoading(false);
        });
    }
  }, [isAuthenticated, bearerToken, selectedLandlord.landlordid, id]);

  const handleInputChange = (name, value) => {
    setEditFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [newContactData, setNewContactData] = useState({
    name: "",
    email: "",
    phone: "",
    type: [],
  });
  // const handleNewContactChange = (name, value) => {
  //   setNewContactData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleNewContactChange = (key, value) => {
    setNewContactData((prevData) => ({
      ...prevData,
      [key]: Array.isArray(value) ? value : [value],
    }));
  };

  const handleInlineInputSave = ({ data }) => {
    console.log("handleInputSave", data);
    setIsSaving(true);
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .put(
        `${apiConfig.hubspotApiUrl}companies/signups/${id}`,
        {
          form_data: data || editFormData,
        },
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("successful update", response.data);
        }
        setIsSaving(false);
        setLastSaved(moment().tz("Europe/London"));
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
      });
  };

  const handleInputSave = (data) => {
    console.log("handleInputSave", data);
    setIsSaving(true);
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .put(
        `${apiConfig.hubspotApiUrl}companies/signups/${id}`,
        {
          form_data: data || editFormData,
        },
        axiosConfig
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("successful update", response.data);
        }
        setIsSaving(false);
        setLastSaved(moment().tz("Europe/London"));
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
      });
  };

  const [, setCurrentTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().tz("Europe/London"));
    }, 5000); // Update every minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const handleRemoveHubspotContact = (contactIndex) => {
    const updatedContacts = [...editFormData.company_contacts];
    updatedContacts[contactIndex].status = "removed";
    setEditFormData((prevState) => ({
      ...prevState,
      company_contacts: updatedContacts,
    }));
    handleInputSave();
  };
  const handleUndoRemoveHubspotContact = (contactIndex) => {
    const updatedContacts = [...editFormData.company_contacts];
    updatedContacts[contactIndex].status = "existing";
    setEditFormData((prevState) => ({
      ...prevState,
      company_contacts: updatedContacts,
    }));
    handleInputSave();
  };

  const handleAddContact = () => {
    const newContact = {
      name: newContactData.name,
      type: newContactData.type,
      email: newContactData.email,
      phone: newContactData.phone,
    };

    setEditFormData((prevState) => {
      const updatedContacts = [...prevState.new_company_contacts, newContact];
      const updatedState = {
        ...prevState,
        new_company_contacts: updatedContacts,
      };

      handleInputSave(updatedState);

      return updatedState;
    });

    setNewContactData({
      name: "",
      email: "",
      phone: "",
      type: [],
    });
  };

  const handleRemoveContact = (index) => {
    setEditFormData((prevState) => {
      const updatedContacts = [...prevState.new_company_contacts];
      updatedContacts.splice(index, 1);
      const updatedState = {
        ...prevState,
        new_company_contacts: updatedContacts,
      };

      // Call handleInputSave with the updated state
      handleInputSave(updatedState);

      return updatedState;
    });
  };

  const handleSetApprovalStatus = (status, message = "") => {
    setEditFormData((prevState) => {
      const updatedStatus = {
        ...prevState,
        status,
        approval_message: message,
      };
      handleInputSave(updatedStatus);
      return updatedStatus;
    });
  };

  const checkContactTypes = () => {
    const allContacts = [
      ...editFormData.company_contacts,
      ...editFormData.new_company_contacts,
    ];
    const hasBillingContact = allContacts.some((contact) =>
      contact.type.includes("Billing")
    );
    const hasTechnicalContact = allContacts.some((contact) =>
      contact.type.includes("Technical")
    );

    if (!hasBillingContact || !hasTechnicalContact) {
      return false;
    }

    return true;
  };

  return (
    <Card>
      {loading && (
        <Card>
          <Card.Body>
            <Loader center content="Loading..." className="m-4" />
          </Card.Body>
        </Card>
      )}
      {!loading && noData && <NotFound />}
      {!loading && !noData && (
        <>
          <Card.Header className="pb-0">
            <Card.Title className="mb-0">
              <div className="float-end">
                <h3>
                  <Badge bg={statusColourMap[editFormData.status]}>
                    {editFormData.status.charAt(0).toUpperCase() +
                      editFormData.status.slice(1)}
                  </Badge>
                </h3>
              </div>
              <div className="float-end">
                {isSaving && (
                  <Loader
                    content="Saving..."
                    className="mx-3 my-1 text-muted"
                  />
                )}
                {!isSaving && lastSaved && (
                  <>
                    <span className="mx-3 mt-2 text-muted text-sm font-weight-normal">
                      Saved {moment(lastSaved).fromNow()}
                    </span>
                  </>
                )}
              </div>
              Editing Registration Form #{id}
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-0">
            <Row className="m-2">
              <Col className="p-2">
                {editFormData.status === "pending" && (
                  <IsAllowed to="edit:signups">
                    <Alert
                      variant="light"
                      style={{ border: "1px solid #dee2e6" }}
                    >
                      <div className="alert-message">
                        <h4 className="alert-heading">Pending Approval</h4>
                        <p>
                          This customer registration form is pending approval.
                          Please review the contents of the customer
                          registration form and either approve or reject it. You
                          can supply a message to let the assignee know what you
                          want them to do.
                        </p>
                        <hr />
                        <div className="btn-list">
                          <Input
                            type="text"
                            placeholder="Message"
                            className="my-3"
                            value={approvalMessage}
                            onChange={(e) => {
                              setApprovalMessage(e);
                            }}
                          />
                          <Button
                            variant="success"
                            className="me-1"
                            onClick={() => {
                              handleSetApprovalStatus(
                                "approved",
                                approvalMessage
                              );
                              setApprovalMessage("");
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              handleSetApprovalStatus(
                                "rejected",
                                approvalMessage
                              );
                              setApprovalMessage("");
                            }}
                          >
                            Reject
                          </Button>
                        </div>
                      </div>
                    </Alert>
                  </IsAllowed>
                )}

                {editFormData.status === "draft" && (
                  <>
                    <Message showIcon type="info" className="mb-4">
                      This customer registration form is currently in draft
                      mode. You can edit the customer details and submit it for
                      approval. Once approved, the customer details will be
                      registed in Hubspot.
                    </Message>
                    <hr />
                  </>
                )}

                {editFormData.status === "approved" && (
                  <>
                    <Message showIcon type="success" className="mb-4">
                      This customer registration form has been approved. It will
                      now automatically be registered in Hubspot. Once this has
                      been completed the status of this registration form will
                      be updated to "Completed".
                      {editFormData.approval_message &&
                        editFormData.approval_message !== "" && (
                          <Text as="blockquote" className="my-3">
                            "{editFormData.approval_message}"
                            {editFormData.approval_user &&
                            editFormData.approval_user !== ""
                              ? " - " + editFormData.approval_user
                              : ""}
                          </Text>
                        )}
                    </Message>

                    <hr />
                  </>
                )}

                {editFormData.status === "rejected" && (
                  <>
                    <Message showIcon type="error" className="mb-4">
                      This customer registration form has been rejected. You can
                      return the form to draft status and make the necessary
                      updates to resubmit for approval.
                      {editFormData.approval_message &&
                        editFormData.approval_message !== "" && (
                          <Text as="blockquote" className="my-3">
                            "{editFormData.approval_message}"
                            {editFormData.approval_user &&
                            editFormData.approval_user !== ""
                              ? " - " + editFormData.approval_user
                              : ""}
                          </Text>
                        )}
                    </Message>

                    <hr />
                  </>
                )}

                <Form fluid>
                  <h4 className="my-3">Company Details</h4>
                  <Field
                    label="Hubspot ID"
                    placeholder="Not In Hubspot"
                    as={Input}
                    value={editFormData.hubspot_id}
                    onChange={(value) => handleInputChange("hubspot_id", value)}
                    rows={1}
                    disabled
                    active={true}
                  />
                  <Field
                    label="Company Name"
                    as={Input}
                    value={editFormData.company_name}
                    onChange={(value) =>
                      handleInputChange("company_name", value)
                    }
                    onSave={handleInlineInputSave}
                    rows={1}
                    disabled={editFormData.status !== "draft"}
                    active={true}
                  />
                  <Field
                    label="Company Domain"
                    as={Input}
                    value={editFormData.company_domain}
                    onChange={(value) =>
                      handleInputChange("company_domain", value)
                    }
                    onSave={handleInlineInputSave}
                    rows={1}
                    disabled={editFormData.status !== "draft"}
                    active={true}
                  />
                  <Field
                    label="Company Phone Number"
                    as={Input}
                    value={editFormData.company_phone}
                    onChange={(value) =>
                      handleInputChange("company_phone", value)
                    }
                    onSave={handleInlineInputSave}
                    rows={1}
                    defaultCountry="gb"
                    disabled={editFormData.status !== "draft"}
                    active={true}
                  />
                  <Field
                    label="Company Registration Number"
                    as={Input}
                    defaultValue={"Company Registration Number"}
                    value={editFormData.company_number}
                    onChange={(value) =>
                      handleInputChange("company_number", value)
                    }
                    onSave={handleInputSave}
                    rows={1}
                    disabled={editFormData.status !== "draft"}
                    active={true}
                  />
                  <h4 className="my-3">Company Address</h4>
                  <Field
                    label="Address Line 1"
                    placeholder=""
                    as={Input}
                    value={editFormData.address1}
                    onChange={(value) => handleInputChange("address1", value)}
                    onSave={handleInlineInputSave}
                    rows={1}
                    disabled={editFormData.status !== "draft"}
                    active={true}
                  />
                  <Field
                    label="Address Line 2"
                    placeholder=""
                    as={Input}
                    value={editFormData.address2}
                    onChange={(value) => handleInputChange("address2", value)}
                    onSave={handleInlineInputSave}
                    rows={1}
                    disabled={editFormData.status !== "draft"}
                    active={true}
                  />
                  <Field
                    label="Postal Code"
                    placeholder=""
                    as={Input}
                    value={editFormData.postcode}
                    onChange={(value) => handleInputChange("postcode", value)}
                    onSave={handleInlineInputSave}
                    rows={1}
                    disabled={editFormData.status !== "draft"}
                    active={true}
                  />
                  <h4 className="my-3">Key Contacts</h4>
                  {editFormData.hubspot_id &&
                    editFormData.hubspot_id !== "None" && (
                      <>
                        <h5>Existing Hubspot Contacts List:</h5>
                        {editFormData.status === "draft" && (
                          <Message showIcon type="info" className="mb-4">
                            These are the existing contacts we found in Hubspot
                            for this company. You can choose to remove or keep
                            them.
                          </Message>
                        )}

                        <Row>
                          {editFormData.company_contacts.map(
                            (contact, index) => (
                              <Col sm={6} className="d-flex" key={index}>
                                <Card className="card w-50 border flex-fill mx-1">
                                  <Card.Header>
                                    <Card.Title className="mb-2">
                                      {contact.name || "--"}
                                      <div className="float-end">
                                        <h5>
                                          {contact.status === "removed" &&
                                            editFormData.status === "draft" && (
                                              <Button
                                                variant="outline-info"
                                                onClick={() =>
                                                  handleUndoRemoveHubspotContact(
                                                    index
                                                  )
                                                }
                                                className="btn-sm"
                                              >
                                                Undo Remove
                                              </Button>
                                            )}
                                          {["new", "existing"].includes(
                                            contact.status
                                          ) &&
                                            editFormData.status === "draft" && (
                                              <Button
                                                variant="outline-danger"
                                                onClick={() =>
                                                  handleRemoveHubspotContact(
                                                    index
                                                  )
                                                }
                                                className="btn-sm"
                                              >
                                                Remove
                                              </Button>
                                            )}
                                        </h5>
                                      </div>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                      {contact.type}{" "}
                                      <Badge className="mx-2" bg="secondary">
                                        {contact.status === "existing"
                                          ? "Keep In Hubspot"
                                          : "Remove From Hubspot"}
                                      </Badge>
                                    </Card.Subtitle>
                                    <Card.Body className="p-0">
                                      <Table size="sm m-0">
                                        <tbody>
                                          <tr>
                                            <th>Email</th>
                                            <td>{contact.email || "--"}</td>
                                          </tr>
                                          <tr>
                                            <th>Phone</th>
                                            <td>{contact.phone || "--"}</td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </Card.Body>
                                  </Card.Header>
                                </Card>
                              </Col>
                            )
                          )}
                        </Row>
                      </>
                    )}

                  <h5>New Contacts:</h5>
                  {editFormData.status === "draft" && (
                    <>
                      <Message showIcon type="info" className="mb-4">
                        Provide contact details for any new key contacts that
                        should be registered in Hubspot for this company. The
                        company must have at least one contact for Billing and
                        Technical.
                      </Message>
                      <Row>
                        <Field
                          label="Contact Name"
                          as={Input}
                          value={newContactData.name}
                          onChange={(value) =>
                            handleNewContactChange("name", value)
                          }
                          rows={1}
                          active={true}
                        />
                        <Field
                          label="Contact Email"
                          as={Input}
                          value={newContactData.email}
                          onChange={(value) =>
                            handleNewContactChange("email", value)
                          }
                          rows={1}
                          active={true}
                        />
                        <Field
                          label="Contact Phone"
                          as={Input}
                          value={newContactData.phone}
                          onChange={(value) =>
                            handleNewContactChange("phone", value)
                          }
                          rows={1}
                          active={true}
                        />
                        <Field
                          label="Contact Type"
                          as={CheckPicker}
                          showControls={true}
                          value={
                            Array.isArray(newContactData.type)
                              ? newContactData.type
                              : []
                          } // ensure value is an array
                          onChange={(value) =>
                            handleNewContactChange("type", value)
                          }
                          rows={1}
                          active={true}
                          searchable={false}
                          appearance={"subtle"}
                          countable={false}
                          data={[
                            { label: "Primary", value: "Primary" },
                            { label: "Billing", value: "Billing" },
                            { label: "Technical", value: "Technical" },
                            { label: "Key Contact", value: "Key Contact" },
                          ]}
                        />
                        <Button
                          className="m-3"
                          style={{ width: "auto" }}
                          onClick={() => {
                            handleAddContact();
                          }}
                          variant="secondary"
                          disabled={
                            !newContactData.name ||
                            !newContactData.email ||
                            !newContactData.type ||
                            !newContactData.phone
                          }
                        >
                          Add
                        </Button>
                      </Row>
                    </>
                  )}

                  <Row>
                    {editFormData.new_company_contacts.map((contact, index) => (
                      <Col sm={6} className="d-flex" key={index}>
                        <Card className="card w-50 border flex-fill mx-1">
                          <Card.Header>
                            <Card.Title className="mb-2">
                              {contact.name || "--"}
                              {editFormData.status === "draft" && (
                                <div className="float-end">
                                  <h5>
                                    <Button
                                      variant="outline-danger"
                                      onClick={() => handleRemoveContact(index)}
                                      className="btn-sm"
                                    >
                                      Remove
                                    </Button>
                                  </h5>
                                </div>
                              )}
                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                              {Array.isArray(contact.type)
                                ? contact.type.join(", ")
                                : contact.type}{" "}
                              <Badge className="mx-2" bg="info">
                                New Contact
                              </Badge>
                            </Card.Subtitle>
                            <Card.Body className="p-0">
                              <Table size="sm m-0">
                                <tbody>
                                  <tr>
                                    <th>Email</th>
                                    <td>{contact.email || "--"}</td>
                                  </tr>
                                  <tr>
                                    <th>Phone</th>
                                    <td>{contact.phone || "--"}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Card.Header>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  {/* Add other fields as necessary */}
                </Form>
                <hr />
                {editFormData.status === "draft" && (
                  <p>
                    Once you are happy with the information provided you can
                    submit your new customer registration for approval.
                  </p>
                )}
                {editFormData.status === "pending" && (
                  <p>
                    This form has been submitted for approval. You can edit it
                    by returning it to draft status.
                  </p>
                )}
                {editFormData.status === "rejected" && (
                  <>
                    <p>
                      This form has been rejected. You can edit it by returning
                      it to draft status.
                    </p>
                  </>
                )}
                {editFormData.status === "approved" && (
                  <p>This form has been approved. You can no longer edit it.</p>
                )}
                {["draft", "rejected", "pending"].includes(
                  editFormData.status
                ) && (
                  <Whisper
                    placement="right"
                    controlId="control-id-hover"
                    trigger={checkContactTypes() ? null : "hover"}
                    speaker={tooltip(
                      "You must supply at least one Technical and one Billing contact to register a customer."
                    )}
                  >
                    <span>
                      <Button
                        style={{ width: "auto" }}
                        disabled={checkContactTypes() ? false : true}
                        onClick={() => {
                          handleSetApprovalStatus(
                            ["pending", "rejected"].includes(
                              editFormData.status
                            )
                              ? "draft"
                              : "pending"
                          );
                        }}
                      >
                        {editFormData.status === "pending" ||
                        editFormData.status === "rejected"
                          ? "Edit Draft"
                          : "Submit For Approval"}
                      </Button>
                    </span>
                  </Whisper>
                )}
              </Col>
            </Row>
          </Card.Body>
        </>
      )}
    </Card>
  );
};

export default EditCustomerRegistrationForm;

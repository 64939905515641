import React from "react";
import { Badge, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

const tableData = [
  {
    opensince: "11/08/2022",
    description: "I am having trouble viewing my invoices",
    ticketref: "00294222",
    category: "Billing",
    status: "Responded",
  },
  {
    opensince: "30/07/2022",
    description: "Firewall rule update",
    ticketref: "00293332",
    category: "Network",
    status: "Responded",
  },
  {
    opensince: "02/08/2022",
    description: "Please set a divert on my landline",
    ticketref: "00239848",
    category: "Telephony",
    status: "Responded",
  },
  {
    opensince: "25/05/2022",
    description: "My printer won't connect to Wi-Fi",
    ticketref: "00239848",
    category: "Wi-Fi",
    status: "Responded",
  },
  {
    opensince: "11/07/2022",
    description: "Struggling to reset my wifi password, please help????",
    ticketref: "00239848",
    category: "Wi-Fi",
    status: "Responded",
  },
  {
    opensince: "01/04/2022",
    description: "My invoice for March 2022 is incorrect",
    ticketref: "00231833",
    category: "Billing",
    status: "Responded",
  },
  {
    opensince: "12/03/2022",
    description: "Do you have FAQs somewhere?",
    ticketref: "00233789",
    category: "General",
    status: "Responded",
  },
];

const statusColourMap = {
  "Customer Responded": "danger",
  "Customer Responded*": "danger",
  "In Progress": "info",
  "On-Hold": "info",
  "Waiting on Customer": "info",
  "Customer Chase Sent*": "success",
  Responded: "success",
  Resolved: "dark",
  "Resolved*": "dark",
};

const statusStyleMap = {
  "Customer Responded": "",
  "Customer Responded*": "",
  "In Progress": "",
  "On-Hold": "",
  "Waiting on Customer": "",
  "Customer Chase Sent*": "",
  Responded: "",
  Resolved: "",
  "Resolved*": "",
};

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Form.Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  );
}

const tableColumns = [
  {
    Header: "Reference",
    accessor: "TicketID",
    Cell: (e) => (
      <div className="text-center">
        <Link to={"/tickets/view/" + e.value}>
          <Button variant="outline-primary">{e.value}</Button>
        </Link>
      </div>
    ),
  },
  {
    Header: "Description",
    accessor: "TicketSummary",
  },
  {
    Header: "Requester",
    accessor: "Requester",
  },
  {
    Header: "Open since",
    accessor: "OpenSince",
    Cell: (e) => moment(e.value).local().format("DD-MM-YYYY HH:mm"),
  },
  {
    Header: "Status",
    accessor: "TicketStatus",
    Filter: SelectColumnFilter,
    filter: "includes",
    Cell: (e) => (
      <Badge bg={statusColourMap[e.value]} className={statusStyleMap[e.value]}>
        {e.value}
      </Badge>
    ),
  },
];

export { tableData, tableColumns };

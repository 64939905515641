import React from "react";
import Chart from "react-apexcharts";

import usePalette from "../../hooks/usePalette";

import { Card, Col, Row, Tab } from "react-bootstrap";

import IsAllowed from "../../components/IsAllowed";

import DeviceStat from "./DeviceStat";

const DeviceStats = ({ data, selectedDevice }) => {
  const palette = usePalette();

  const options = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#FFF",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: true,
            offsetY: 0,
            color: "#6c757d",
          },
          value: {
            offsetY: -45,
            fontSize: "22px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    colors: [palette.connectedorange],
    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: [palette.lighterorange],
        shade: "light",
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
      },
    },
  };

  const cpu_label = { labels: ["CPU Usage"] };
  const memory_label = { labels: ["Memory Usage"] };
  const uptime_label = { labels: ["Device Uptime"] };

  const memory_options = {
    ...options,
    ...memory_label,
  };

  const cpu_options = {
    ...options,
    ...cpu_label,
  };

  const uptime_options = {
    ...options,
    ...uptime_label,
  };

  const selectedDeviceStats = data.find(
    (device) => device.deviceInfo.id === selectedDevice.deviceInventoryId
  );

  let uptime_data = selectedDeviceStats?.deviceUptime
    ? [selectedDeviceStats.deviceUptime]
    : ["No Data"];
  let cpu_data = selectedDeviceStats?.deviceResourceStats?.cpu
    ? [selectedDeviceStats.deviceResourceStats.cpu]
    : ["No Data"];
  let memory_data = selectedDeviceStats?.deviceResourceStats?.memory
    ? [selectedDeviceStats.deviceResourceStats.memory]
    : ["No Data"];

  const deviceData = [
    {
      label: "Hostname",
      value: selectedDeviceStats.deviceInfo.device_hostname,
    },
    {
      label: "Manufacturer",
      value: selectedDeviceStats.deviceInfo.device_manufacturer,
    },
    {
      label: "Model",
      value: selectedDeviceStats.deviceInfo.device_model,
    },
    {
      label: "Details",
      value: selectedDeviceStats.deviceInfo.device_type,
    },
    {
      label: "Platform",
      value: selectedDeviceStats.deviceInfo.device_platform,
    },
    {
      label: "Role",
      value: selectedDeviceStats.deviceInfo.device_role,
    },
    {
      label: "Management IP Address",
      value: selectedDeviceStats.deviceInfo.device_ip,
    },
    {
      label: "Status",
      value: selectedDeviceStats.deviceInfo.device_status,
    },
    {
      label: "Location",
      value: selectedDeviceStats.deviceInfo.location_name
        ? `${selectedDeviceStats.deviceInfo.location_name}`
        : null,
    },
    {
      label: "Serial Number",
      value: selectedDeviceStats.deviceInfo.device_serial,
    },
  ];

  const deviceStats = selectedDeviceStats ? (
    <Card.Body>
      <IsAllowed to="view:monitoring:deviceinfo">
        <Row className="text-center">
          {deviceData.map(
            (stat, index) =>
              stat.value !== null &&
              stat.value !== undefined && <DeviceStat key={index} data={stat} />
          )}
        </Row>
      </IsAllowed>
      <Row>
        {uptime_data[0] !== "No Data" && (
          <Col xl="4">
            <div className="align-self-center w-100">
              <div className="chart chart-xs">
                <Chart
                  options={uptime_options}
                  series={uptime_data}
                  type="radialBar"
                  height="350"
                />
              </div>
            </div>
          </Col>
        )}
        {cpu_data[0] !== "No Data" && (
          <Col xl="4">
            <div className="align-self-center w-100">
              <div className="chart chart-xs">
                <Chart
                  options={cpu_options}
                  series={cpu_data}
                  type="radialBar"
                  height="350"
                />
              </div>
            </div>
          </Col>
        )}
        {memory_data[0] !== "No Data" && (
          <Col xl="4">
            <div className="align-self-center w-100">
              <div className="chart chart-xs">
                <Chart
                  options={memory_options}
                  series={memory_data}
                  type="radialBar"
                  height="350"
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Card.Body>
  ) : (
    <Card.Body>
      <span className="d-block mx-auto p-3 text-center">
        No device selected
      </span>
    </Card.Body>
  );

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">Device Details</Card.Title>
      </Card.Header>
      <Tab.Content>{deviceStats}</Tab.Content>
    </Card>
  );
};

export default DeviceStats;

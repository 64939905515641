import React from "react";

const NoData = ({ noDataMessage }) => {
  return (
    <React.Fragment>
      <div className="text-center p-2" style={{ minHeight: 80 }}>
        <p className="h3 fw-normal mt-4 mb-1 text-muted">
          {noDataMessage ? noDataMessage : "No data available."}
        </p>
      </div>
    </React.Fragment>
  );
};

export default NoData;

import React, { useState, useEffect, useRef } from "react";
import { Alert, Form, Modal, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestion,
  // faInfo,
  faCaretDown,
  faCheck,
  faExclamation,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import DatePicker from "../DatePicker";
import { format } from "date-fns";
import {
  Slider,
  RadioTile,
  RadioTileGroup,
  useMediaQuery,
  Message,
  // Placeholder,
  Table,
} from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import { quantum, dotPulse } from "ldrs";
// import { JSONTree } from "react-json-tree";
import { LazyLog } from "react-lazylog";
import { useNavigate } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;
quantum.register();
dotPulse.register();

const renderErrorMessages = (error) => {
  if (typeof error === "object" && error !== null) {
    return Object.keys(error).map((key, index) => (
      <div key={index} className="text-warning">
        {error[key]}
      </div>
    ));
  }
  return <div className="text-warning">{error}</div>;
};

const FormPage = ({
  formConfig,
  stepConfig,
  setFieldValue,
  // handleChange,
  // handleSelect,
  errors,
  touched,
  handleBlur,
  values,
  validateField,
  setFieldTouched,
  myFormData,
  setMyFormData,
  isSubmissionStep,
  // isLastStep,
  // error,
  // success,
  submissionStatus,
  // tasksStatus,
  isConfirmationNeeded,
  handleUserConfirmation,
  userAccessToken,
}) => {
  const [isInline] = useMediaQuery("xl");
  const [passedResult, setPassedResult] = useState({});

  const navigate = useNavigate();

  const initOpenModals = () => {
    let modals = {};
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index, taskId = null) => {
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  useEffect(() => {
    if (submissionStatus.tasks) {
      submissionStatus.tasks.forEach((task) => {
        if (
          task.status === "loading" ||
          task.status === "succeeded" ||
          task.status === "failed"
        ) {
          setPassedResult(task.payload);
        }
      });
    }
  }, [submissionStatus.tasks]);

  // External Query Handling

  const [loadingExternalQuery, setLoadingExternalQuery] = useState(false);
  const [externalQueryResults, setExternalQueryResults] = useState([]);
  const [externalQueryError, setExternalQueryError] = useState("");

  const myFormDataRef = useRef(myFormData);

  useEffect(() => {
    myFormDataRef.current = myFormData;
  }, [myFormData]);

  useEffect(() => {
    if (
      stepConfig.stepType === "externalQuery__hubspot" &&
      stepConfig.queryHandler
    ) {
      stepConfig.queryHandler(
        myFormDataRef.current.company_name,
        myFormDataRef.current.company_domain,
        myFormDataRef.current.company_reg_number,
        userAccessToken,
        setLoadingExternalQuery,
        setExternalQueryResults
      );
    }

    if (
      stepConfig.stepType === "externalQuery__pricing" &&
      stepConfig.queryHandler
    ) {
      stepConfig.queryHandler(
        myFormDataRef.current,
        userAccessToken,
        setLoadingExternalQuery,
        setExternalQueryResults,
        setExternalQueryError,
        setMyFormData
      );
    }
  }, [
    stepConfig,
    // myFormData,
    setMyFormData,
    myFormData.company_name,
    myFormData.company_domain,
    myFormData.company_reg_number,
    userAccessToken,
  ]);

  if (isSubmissionStep && submissionStatus.tasks) {
    return (
      <div className="pb-4">
        <h3 className="text-dark display-6">{stepConfig.title}</h3>
        {submissionStatus.tasks.map((task, index) => (
          <div key={index} className="fs-4 m-3">
            {task.status === "loading" && (
              <l-quantum size="15" color="#3F80EA" fixedWidth></l-quantum>
            )}
            {task.status === "pending" && (
              <FontAwesomeIcon
                icon={faPause}
                // className="text-dark"
                fixedWidth
              />
            )}
            {(task.status === "succeeded" || task.status === "no_action") && (
              <FontAwesomeIcon
                icon={faCheck}
                className="text-success"
                fixedWidth
              />
            )}
            {task.status === "failed" && (
              <FontAwesomeIcon
                icon={faExclamation}
                className="text-warning"
                fixedWidth
              />
            )}
            <strong className="ms-3">{task.name}</strong>
            {task.description && (
              <p className="text-small text-muted fs-6 ms-5 mb-0">
                {task.description}
              </p>
            )}
            {/*{task.status === "loading" && (
              <>
                <Button
                  className="m-2"
                  variant="light"
                  size="md"
                  onClick={() => {
                    toggle("viewResult");
                    setPassedResult(task.payload);
                    console.log("Passed progress:", task.payload);
                  }}
                >
                  View Progress
                </Button>
              </>
            )} */}
            {(task.status === "succeeded" ||
              task.status === "loading" ||
              task.status === "failed") && (
              <div
                className={
                  task.status === "failed"
                    ? "text-warning fs-6 ms-5"
                    : "text-success fs-6 ms-5"
                }
              >
                {task.status === "succeeded"
                  ? task.message
                  : task.status === "failed"
                  ? task.message
                  : "Task in progress..."}
                <br />
                {/* <a href="#" onClick={() => toggle("viewManifest")}>
                  Click to view
                </a> */}
                {task.status !== "no_action" && task.payload && (
                  <Button
                    className="m-2"
                    variant="light"
                    size="md"
                    onClick={() => {
                      toggle("viewResult");
                      setPassedResult(task.payload);
                    }}
                  >
                    {task.status === "loading"
                      ? "View Progress"
                      : "View Results"}
                  </Button>
                )}

                {isConfirmationNeeded && (
                  <Button
                    className="m-2"
                    variant="success"
                    size="md"
                    onClick={handleUserConfirmation}
                  >
                    {task.continueText
                      ? task.continueText
                      : "Continue Workflow"}
                  </Button>
                )}

                {task.ctaLink && (
                  <Button
                    className="m-2"
                    variant="light"
                    size="md"
                    onClick={() => {
                      toggle(formConfig.name);
                      navigate(task.ctaLink.link);
                    }}
                  >
                    {task.ctaLink.label}
                  </Button>
                )}
                <Modal
                  show={openModals["viewResult"]}
                  onHide={() => {
                    toggle("viewResult", task.payload.task_id);
                    setPassedResult({});
                  }}
                  size="xl"
                  centered
                  scrollable
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Task logs</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-2">
                    {passedResult ? (
                      <LazyLog
                        text={passedResult}
                        selectableLines={true}
                        height={500}
                        enableSearch={true}
                        extraLines={1}
                        follow={true}
                      />
                    ) : (
                      <LazyLog
                        text={"Loading..."}
                        selectableLines={true}
                        height={500}
                        enableSearch={true}
                        extraLines={1}
                        follow={true}
                      /> // Provide a loading state or similar fallback
                    )}
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>
            )}
            {/* {task.status === "failed" && (
              <div className="text-warning fs-6 ms-5">
                {task.message}
                <br />
                <a href="#">Start troubleshooting</a>
              </div>
            )} */}
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      <div className="pb-4">
        <h3 className="text-dark display-6">{stepConfig.title}</h3>
      </div>
      {stepConfig.longDescription && (
        <Message type="info" className="mb-4">
          {stepConfig.longDescription}
        </Message>
      )}

      {["input", "review"].includes(stepConfig.stepType) && (
        <div className="fv-row mb-6">
          {stepConfig.fields.map((field, index) => {
            const shouldDisplay = field.condition
              ? field.condition(values)
              : true;

            if (!shouldDisplay) {
              return null;
            }

            if (field.type === "text") {
              return (
                <div key={index} className="mb-4">
                  <Form.Group
                    className={field.condition ? "ms-3" : ""}
                    controlId={field.name}
                  >
                    <Form.Label
                      className={field.condition ? "fs-5" : "fs-4 text-dark"}
                    >
                      {field.condition ? (
                        <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                      ) : (
                        ""
                      )}
                      {field.title || field.label}
                    </Form.Label>
                    {field.infoBox ? (
                      <Alert variant="primary" className="alert-outline">
                        <div className="alert-icon d-flex align-items-center">
                          <FontAwesomeIcon icon={faQuestion} fixedWidth />
                        </div>
                        <div className="alert-message">{field.infoBox}</div>
                      </Alert>
                    ) : (
                      ""
                    )}
                    <Form.Control
                      type="text"
                      name={field.name}
                      value={values[field.name] || ""}
                      onChange={(e) => {
                        setFieldValue(field.name, e.target.value);
                        setFieldTouched(field.name, true, false);
                        validateField(field.name)
                          .then(() => {
                            setMyFormData({
                              ...myFormData,
                              [field.name]: e.target.value || "",
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                      onBlur={handleBlur}
                    />
                    {errors[field.name] && touched[field.name] && (
                      <div className="mt-1">
                        {renderErrorMessages(errors[field.name])}
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            }

            if (field.type === "select") {
              return (
                <div key={index} className="mb-4">
                  <Form.Group className={field.condition ? "ms-3" : ""}>
                    <Form.Label
                      className={field.condition ? "fs-5" : "fs-4 text-dark"}
                    >
                      {field.condition ? (
                        <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                      ) : (
                        ""
                      )}
                      {field.title || field.label}
                    </Form.Label>
                    {field.infoBox ? (
                      <Alert variant="primary" className="alert-outline">
                        <div className="alert-icon d-flex align-items-center">
                          <FontAwesomeIcon icon={faQuestion} fixedWidth />
                        </div>
                        <div className="alert-message">{field.infoBox}</div>
                      </Alert>
                    ) : (
                      ""
                    )}
                    <Select
                      name={field.name}
                      options={field.options}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={(option) => {
                        // setFieldValue(field.name, option.value);
                        setFieldValue(field.name, {
                          value: option.value,
                          label: option.label,
                        });
                        setFieldTouched(field.name, true, false);
                        validateField(field.name)
                          .then(() => {
                            setMyFormData({
                              ...myFormData,
                              [field.name]: {
                                value: option.value,
                                label: option.label,
                              },
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                      value={field.options.find(
                        (option) =>
                          myFormData[field.name]?.value === option.value
                      )}
                      onBlur={handleBlur}
                      // isClearable={true}
                    />
                    {errors[field.name] && touched[field.name] && (
                      <div className="mt-1">
                        {renderErrorMessages(errors[field.name])}
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            }

            if (field.type === "lazyselect") {
              return (
                <div key={index} className="mb-4">
                  <Form.Group className={field.condition ? "ms-3" : ""}>
                    <Form.Label
                      className={field.condition ? "fs-5" : "fs-4 text-dark"}
                    >
                      {field.condition ? (
                        <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                      ) : (
                        ""
                      )}
                      {field.title || field.label}
                    </Form.Label>
                    {field.infoBox ? (
                      <Alert variant="primary" className="alert-outline">
                        <div className="alert-icon d-flex align-items-center">
                          <FontAwesomeIcon icon={faQuestion} fixedWidth />
                        </div>
                        <div className="alert-message">{field.infoBox}</div>
                      </Alert>
                    ) : (
                      ""
                    )}
                    <Select
                      name={field.name}
                      options={field.options}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={(option) => {
                        // setFieldValue(field.name, option.value);
                        setFieldValue(field.name, {
                          value: option.value,
                          label: option.label,
                        });
                        setFieldTouched(field.name, true, false);
                        validateField(field.name)
                          .then(() => {
                            setMyFormData({
                              ...myFormData,
                              [field.name]: {
                                value: option.value,
                                label: option.label,
                              },
                            });
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                      value={field.options.find(
                        (option) =>
                          myFormData[field.name]?.value === option.value
                      )}
                      onBlur={handleBlur}
                      // isClearable={true}
                    />
                    {errors[field.name] && touched[field.name] && (
                      <div className="mt-1">
                        {renderErrorMessages(errors[field.name])}
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            }

            if (field.type === "checkbox") {
              return (
                <div key={index} className="mb-4">
                  <Form.Group className={field.condition ? "ms-3" : ""}>
                    <Form.Label
                      className={field.condition ? "fs-5" : "fs-4 text-dark"}
                    >
                      {field.condition ? (
                        <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                      ) : (
                        ""
                      )}
                      {field.title || field.label}
                    </Form.Label>
                    {field.infoBox ? (
                      <Alert variant="primary" className="alert-outline">
                        <div className="alert-icon">
                          <FontAwesomeIcon icon={faQuestion} fixedWidth />
                        </div>
                        <div className="alert-message">{field.infoBox}</div>
                      </Alert>
                    ) : (
                      ""
                    )}
                    <Form.Check
                      type="checkbox"
                      name={field.name}
                      id={field.name}
                      label={field.label}
                      checked={values[field.name]}
                      onChange={(event) => {
                        const { checked } = event.target;
                        setFieldValue(field.name, checked); // Directly use the checked value
                        setFieldTouched(field.name, true, false);

                        validateField(field.name)
                          .then(() => {
                            // If validation passes, update your form data state
                            setMyFormData((prevFormData) => ({
                              ...prevFormData,
                              [field.name]: checked,
                            }));
                          })
                          .catch((error) => {
                            // Handle the validation error if needed
                            console.log(error);
                          });
                      }}
                      onBlur={handleBlur}
                    />
                    {errors[field.name] && touched[field.name] && (
                      <div className="text-warning mt-1">
                        {errors[field.name]}
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            }
            if (field.type === "switch") {
              return (
                <div key={index} className="mb-4">
                  <Form.Group className={field.condition ? "ms-3" : ""}>
                    <Form.Label
                      className={field.condition ? "fs-5" : "fs-4 text-dark"}
                    >
                      {field.condition ? (
                        <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                      ) : (
                        ""
                      )}
                      {field.title || field.label}
                    </Form.Label>
                    {field.infoBox ? (
                      <Message type="info" className="mb-4">
                        {field.infoBox}
                      </Message>
                    ) : (
                      ""
                    )}
                    <Form.Check
                      type="switch"
                      name={field.name}
                      label={field.label}
                      id={field.name}
                      checked={values[field.name]}
                      onChange={(event) => {
                        const { checked } = event.target;
                        setFieldValue(field.name, checked); // Directly use the checked value
                        setFieldTouched(field.name, true, false);

                        validateField(field.name)
                          .then(() => {
                            // If validation passes, update your form data state
                            setMyFormData((prevFormData) => ({
                              ...prevFormData,
                              [field.name]: checked,
                            }));
                          })
                          .catch((error) => {
                            // Handle the validation error if needed
                            console.log(error);
                          });
                      }}
                      onBlur={handleBlur}
                    />
                    {errors[field.name] && touched[field.name] && (
                      <div className="text-warning mt-1">
                        {errors[field.name]}
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            }
            if (field.type === "date") {
              return (
                <div key={index} className="mb-4">
                  <Form.Group className={field.condition ? "ms-3" : ""}>
                    <Form.Label
                      className={field.condition ? "fs-5" : "fs-4 text-dark"}
                    >
                      {field.condition ? (
                        <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                      ) : (
                        ""
                      )}
                      {field.title || field.label}
                    </Form.Label>
                    <div className=" d-flex justify-content-center">
                      <DatePicker
                        selectedDate={values[field.name]}
                        setSelectedDate={(date) => {
                          setFieldValue(field.name, date);
                          setFieldTouched(field.name, true, false);
                          validateField(field.name)
                            .then(() => {
                              setMyFormData({
                                ...myFormData,
                                [field.name]: date,
                              });
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      />
                    </div>
                    {errors[field.name] && touched[field.name] && (
                      <div className="text-warning mt-1">
                        {errors[field.name]}
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            }
            if (field.type === "range") {
              return (
                <div key={index} className="mb-4">
                  <Form.Group className={field.condition ? "ms-3" : ""}>
                    <Form.Label
                      className={field.condition ? "fs-5" : "fs-4 text-dark"}
                    >
                      {field.condition ? (
                        <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                      ) : (
                        ""
                      )}
                      {field.title || field.label}
                    </Form.Label>
                    <div
                      className="my-3 pb-1"
                      style={{ width: "90%", margin: "0px auto" }}
                    >
                      <Slider
                        defaultValue={values[field.name] || field.options.min}
                        value={values[field.name] || field.options.min}
                        step={field.options.step}
                        graduated
                        progress
                        min={field.options.min}
                        max={field.options.max}
                        renderMark={(mark) => {
                          if (field.options.marks.includes(mark)) {
                            return (
                              <span>
                                {mark} {field.options.unit}
                              </span>
                            );
                          }
                          return null;
                        }}
                        onChange={(value) => {
                          setFieldValue(field.name, value);
                          setFieldTouched(field.name, true, false);
                          validateField(field.name)
                            .then(() => {
                              setMyFormData({
                                ...myFormData,
                                [field.name]: value,
                              });
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      />
                    </div>
                    {errors[field.name] && touched[field.name] && (
                      <div className="text-warning mt-1">
                        {errors[field.name]}
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            }
            if (field.type === "radiotile") {
              return (
                <div key={index} className="mb-4">
                  <Form.Group className={field.condition ? "ms-3" : ""}>
                    <Form.Label
                      className={field.condition ? "fs-5" : "fs-4 text-dark"}
                    >
                      {/* {field.condition ? (
                      <FontAwesomeIcon icon={faCaretDown} className="me-2" />
                    ) : (
                      ""
                    )}*/}
                      {field.title || field.label}
                    </Form.Label>
                    <div
                      className="my-3 mt-0 pb-1"
                      style={{ margin: "0px auto" }}
                    >
                      <RadioTileGroup
                        inline={isInline}
                        aria-label="Radiotile"
                        value={values[field.name]}
                        onChange={(value) => {
                          setFieldValue(field.name, value);
                          setFieldTouched(field.name, true, false);
                          setMyFormData({
                            ...myFormData,
                            [field.name]: value,
                          });
                        }}
                      >
                        {field.options.map((option) => (
                          <RadioTile
                            icon={option.icon}
                            label={option.label}
                            value={option.value}
                            disabled={option.disabled}
                          >
                            {option.description}
                          </RadioTile>
                        ))}
                      </RadioTileGroup>
                    </div>
                    {errors[field.name] && touched[field.name] && (
                      <div className="text-warning mt-1">
                        {errors[field.name]}
                      </div>
                    )}
                  </Form.Group>
                </div>
              );
            }
            if (field.type === "confirm") {
              return (
                <div key={index} className="mb-4">
                  {formConfig.steps.map((step, stepIndex) => {
                    if (["review", "process"].includes(step.stepType)) {
                      return null;
                    }
                    return (
                      <div key={stepIndex}>
                        <h4>{step.title}</h4>
                        {step.fields.map((field, fieldIndex) => {
                          if (
                            myFormData.hasOwnProperty(field.name) &&
                            myFormData[field.name] !== null
                          ) {
                            let value = myFormData[field.name];
                            if (field.type === "date") {
                              value = format(value, "do LLLL yyyy");
                            }
                            if (
                              field.type === "switch" ||
                              field.type === "checkbox"
                            ) {
                              value = value ? "Yes" : "No";
                            }
                            if (field.type === "select") {
                              value = value.label;
                            }
                            return (
                              <div key={fieldIndex}>
                                <h5 className="my-3 ms-3">
                                  <span className="text-muted">
                                    {field.confirmLabel}:
                                  </span>{" "}
                                  {value}
                                </h5>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            }
            // Add other field types (text, date, etc.) as needed
            return null;
          })}
        </div>
      )}

      {["externalQuery__hubspot"].includes(stepConfig.stepType) && (
        <>
          {stepConfig.fields.map((field, index) => {
            if (field.type === "radiotile") {
              return (
                <div
                  className="fv-row mb-6 scrollable-dropdown"
                  style={{ height: "300px" }}
                  key={index}
                >
                  {loadingExternalQuery ? (
                    <div className="text-center">
                      <l-quantum
                        size="15"
                        color="#3F80EA"
                        fixedWidth
                      ></l-quantum>
                      <br />
                      Searching for existing customers...
                    </div>
                  ) : (
                    <RadioTileGroup
                      inline={false}
                      aria-label="Radiotile"
                      value={values[field.name]}
                      onChange={(value) => {
                        setFieldValue(field.name, value);
                        setFieldTouched(field.name, true, false);
                        setMyFormData({
                          ...myFormData,
                          [field.name]: value,
                        });
                      }}
                    >
                      {externalQueryResults.map((company, index) => {
                        return (
                          <RadioTile
                            key={index}
                            label={company.name}
                            value={
                              company.symbillAccounts.length > 0
                                ? company.hubspotId + "*"
                                : company.hubspotId
                            }
                          >
                            <Row className="w-100">
                              <Col sm={12}>Domain: {company.domain}</Col>
                              <Col sm={12}>Phone: {company.phone}</Col>
                              <Col sm={12}>
                                Registration Number:{" "}
                                {company.registrationNumber}
                              </Col>
                              <Col sm={12}>
                                Symbill Account Number:{" "}
                                {company.symbillAccounts.length > 0
                                  ? company.symbillAccounts[0].accountNumber +
                                    (company.symbillAccounts.length > 1
                                      ? " (+" +
                                        (company.symbillAccounts.length - 1) +
                                        " more)"
                                      : "")
                                  : "-"}
                              </Col>
                            </Row>
                            <span className="text-muted float-end">
                              <a
                                href={`https://app-eu1.hubspot.com/contacts/3402328/company/${company.hubspotId}`}
                                target="_blank"
                                rel="noreferrer"
                                className="link-info"
                              >
                                View In Hubspot
                              </a>
                            </span>
                          </RadioTile>
                        );
                      })}

                      <RadioTile
                        label={
                          externalQueryResults.length > 0
                            ? "None of the above"
                            : "No matches"
                        }
                        value="None"
                      >
                        {externalQueryResults.length > 0
                          ? "The customer you want to register is not listed"
                          : "Continue with new customer registration"}
                      </RadioTile>
                    </RadioTileGroup>
                  )}
                  {errors[field.name] && touched[field.name] && (
                    <div className="mt-1">
                      {renderErrorMessages(errors[field.name])}
                    </div>
                  )}
                </div>
              );
            }
            if (field.type === "hidden") {
            }
            return null;
          })}
        </>
      )}

      {["externalQuery__pricing"].includes(stepConfig.stepType) && (
        <>
          <div className="mb-4">
            {loadingExternalQuery ? (
              <div className="text-center">
                <l-quantum size="15" color="#3F80EA" fixedWidth></l-quantum>
                <br />
                Calculating order price summary
              </div>
            ) : (
              <>
                <Table
                  data={externalQueryResults}
                  hover={false}
                  className="my-2"
                  renderEmpty={() => {
                    return (
                      <div className="text-muted text-center m-3">
                        {externalQueryError === ""
                          ? "Could not calculate pricing"
                          : externalQueryError}
                      </div>
                    );
                  }}
                >
                  <Column flexGrow={2}>
                    <HeaderCell>Product</HeaderCell>
                    <Cell dataKey="name" />
                  </Column>
                  {/* <Column>
                    <HeaderCell>Unit Price</HeaderCell>
                    <Cell>{(rowData) => `£${rowData.unitPrice}`}</Cell>
                  </Column> */}
                  <Column>
                    <HeaderCell>Quantity</HeaderCell>
                    <Cell dataKey="qty" />
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Price</HeaderCell>
                    <Cell>{(rowData) => `£${rowData.price}`}</Cell>
                  </Column>
                  <Column>
                    <HeaderCell>Charge Type</HeaderCell>
                    <Cell>
                      {(rowData) =>
                        `${
                          rowData.interval.charAt(0).toUpperCase() +
                          rowData.interval.slice(1)
                        }`
                      }
                    </Cell>
                  </Column>
                </Table>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FormPage;

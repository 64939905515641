import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import OffcanvasHelp from "../../components/OffcanvasHelp";
import { apiConfig } from "../../config";

import {
  Badge,
  Card,
  Col,
  Container,
  ListGroup,
  ProgressBar,
  Row,
  Breadcrumb,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import NetworkAvailabilityLine from "./NetworkAvailability";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import NoData from "../../components/NoData";

import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import axios from "axios";

const PLACEHOLDER_IMAGE = "/assets/img/buildings/placeholder.jpeg";

const getBandwidthPercent = (total_bw, dia_bw) => {
  return ((dia_bw / total_bw) * 100).toFixed(0);
};

const BuildingNetwork = ({
  name,
  status,
  color,
  percentagedia,
  percentageosia,
  netdata,
  netdates,
  technicalreference,
  buildingid,
}) => {
  const getBuildingImagePath = () => {
    const path = `/assets/img/buildings/${technicalreference}.webp`;
    const img = new Image();
    img.src = path;
    img.onerror = () => (img.src = PLACEHOLDER_IMAGE);
    return img.src;
  };

  const handleError = (event) => {
    event.target.onerror = null;
    event.target.src = PLACEHOLDER_IMAGE;
  };
  return (
    <Card>
      <Link
        to={
          netdata[0].data.length !== 0
            ? "/monitoring/building/" + buildingid
            : "#"
        }
      >
        <Card.Img
          src={getBuildingImagePath()}
          alt="Building Image"
          className="card-img-aspect"
          onError={handleError}
        />
        <div className="card-img-overlay">
          <Badge
            className={color === "success" ? "my-2 btn-gradient" : "my-2"}
            bg={color}
          >
            {status}
          </Badge>
        </div>
        <Card.Header className="px-4 pt-4">
          <Card.Title className="mb-0">{name}</Card.Title>
        </Card.Header>
      </Link>
      <Card.Body className="px-4 pt-1 pb-1">
        <p className="mb-2 fw-bold text-muted">Core Router Ping (10d)</p>
        {netdata[0].data.length !== 0 ? (
          <NetworkAvailabilityLine data={netdata} categories={netdates} />
        ) : (
          <NoData />
        )}
      </Card.Body>
      <ListGroup variant="flush">
        <ListGroup.Item className="px-4 pb-4">
          <p className="mb-2 fw-bold text-muted">
            Dedicated Bandwidth Allocation
            <span
              className={
                percentagedia > 80
                  ? "float-end text-danger"
                  : "float-end text-success"
              }
            >
              {percentagedia}%
            </span>
          </p>
          <ProgressBar>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">DIA</Tooltip>}
            >
              <ProgressBar
                className="progress-orange"
                now={percentagedia}
                isChild={true}
                label={percentagedia + "%"}
                key={2}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">Available</Tooltip>}
            >
              <ProgressBar
                className="progress-neutral p-n1"
                now={percentageosia}
                isChild={true}
                label={percentageosia + "%"}
                key={1}
              />
            </OverlayTrigger>
          </ProgressBar>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

const BuildingNetworks = () => {
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const { userAccessToken, isAuthenticated } = useAuth();
  const [buildingsData, setBuildingsData] = useState([]);
  const [sparkLinesData, setSparkLinesData] = useState({});
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    if (isAuthenticated) {
      let buildingsApiUrl =
        apiConfig.connectedApiUrl +
        "buildings?page_size=1000&order_by=building_name%20ASC&building_operator=" +
        selectedLandlord.landlordid;
      let sparkLinesApiUrl =
        apiConfig.monitoringApiUrl +
        "core/sparklines/retrieve/" +
        selectedLandlord.landlordid;
      const buildingsRequest = axios.get(buildingsApiUrl, axiosConfig);
      let sparkLinesRequest = axios.get(sparkLinesApiUrl, axiosConfig);
      axios
        .all([buildingsRequest, sparkLinesRequest])
        .then(
          axios.spread((...responses) => {
            const buildingsResponse = responses[0];
            const sparkLinesResponse = responses[1];
            setBuildingsData(buildingsResponse.data.buildings.items);
            setSparkLinesData(sparkLinesResponse.data);
            setNoData(false);
            setLoading(false);
          })
        )
        .catch((err) => {
          setLoading(false);
          setNoData(true);
          console.log(err);
        });
    }
  }, [bearerToken, selectedLandlord, isAuthenticated]);

  const networksList = [];
  buildingsData.forEach((e) => {
    let availability;
    let dates;
    let bandwidth;
    if (sparkLinesData[e.id.toString()]) {
      availability = [
        {
          name: "Core Router Ping",
          data: sparkLinesData[e.id.toString()].availability,
        },
      ];
      bandwidth = getBandwidthPercent(
        sparkLinesData[e.id.toString()].bandwidth.total_bw,
        sparkLinesData[e.id.toString()].bandwidth.dia_bw
      );
      if (isNaN(bandwidth)) {
        bandwidth = 0;
      }
      dates = sparkLinesData[e.id.toString()].dates;
    } else {
      availability = [
        {
          name: "Core Router Ping",
          data: [],
        },
      ];
    }
    if (availability[0].data.length > 0) {
      if (e.status === "Live Building") {
        networksList.push(
          <Col md="6" lg="3">
            <BuildingNetwork
              name={e.building_name}
              status={e.status}
              color={e.status === "Live Building" ? "success" : "secondary"}
              percentageosia={100 - bandwidth}
              percentagedia={bandwidth}
              description={e.description}
              image=""
              buildingid={e.id}
              key={e.id}
              technicalreference={e.wifi_id}
              netdata={availability}
              netdates={dates}
            />
          </Col>
        );
      }
    }
  });

  const navigate = useNavigate();

  return (
    <IsAllowed to="view:monitoring" fallback={<NotAuthorised />}>
      <React.Fragment>
        <Helmet title="Network Monitoring" />
        <Container fluid className="p-0">
          <Breadcrumb className="float-end mt-2">
            <Breadcrumb.Item onClick={() => navigate("/dashboard/default")}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Network Monitoring</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h3 mb-3">
            Network Monitoring
            <OffcanvasHelp
              id="networkmonitoring"
              name="Network Monitoring"
              scroll
              backdrop
            />
          </h1>
          <div className="clearfix" key="clearfix1"></div>
          <Row className="d-flex">
            {loading && <LogoLoader className="d-block m-auto p-4" />}
            {!loading && noData && <NotFound />}
            {!loading && !noData && networksList}
          </Row>
        </Container>
      </React.Fragment>
    </IsAllowed>
  );
};

export default BuildingNetworks;

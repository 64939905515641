import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSearchFilters: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setSearchFilters } = filtersSlice.actions;

export default filtersSlice.reducer;

import React, { useState, useContext } from "react";

import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Tab,
  Modal,
} from "react-bootstrap";
import { apiConfig } from "../../config";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import OffcanvasHelp from "../../components/OffcanvasHelp";

import Select from "react-select";
import useAuth from "../../hooks/useAuth";
import NotyfContext from "../../contexts/NotyfContext";
import IsAllowed from "../../components/IsAllowed";
import NotAuthorised from "../../components/NotAuthorised";
import NotFound from "../../components/NotFound";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { setRefreshData } from "../../redux/slices/refreshData";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons";

import ColumnFilteringTable from "../../components/tables/ColumnFilteringTable";

import UserManagement from "./components/UserManagement";
import Dot11Admin from "./components/Dot11Admin";

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title className="mb-0">System Settings</Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <IsAllowed to="view:systemsettings:users">
        <ListGroup.Item tag="a" href="#1" action>
          User Management
        </ListGroup.Item>
      </IsAllowed>
      <IsAllowed to="edit:credentials:all">
        <ListGroup.Item tag="a" href="#Dot11Administration" action>
          Dot11 Administration
        </ListGroup.Item>
      </IsAllowed>
      <IsAllowed to="view:systemsettings:roles">
        <ListGroup.Item tag="a" href="#2" action>
          Roles &amp; Permissions
        </ListGroup.Item>
      </IsAllowed>
      <IsAllowed to="view:systemsettings:networks">
        <ListGroup.Item tag="a" href="#6" action>
          Building Network Information
        </ListGroup.Item>
      </IsAllowed>
      <IsAllowed to="view:systemsettings:networks">
        <ListGroup.Item tag="a" href="#7" action>
          Building Network Devices
        </ListGroup.Item>
      </IsAllowed>
      <IsAllowed to="view:systemsettings:buildings">
        <ListGroup.Item tag="a" href="#3" action>
          Buildings
        </ListGroup.Item>
      </IsAllowed>
      <IsAllowed to="view:systemsettings:landlords">
        <ListGroup.Item tag="a" href="#4" action>
          Landlords
        </ListGroup.Item>
      </IsAllowed>
      <IsAllowed to="view:systemsettings:networks">
        <ListGroup.Item tag="a" href="#8" action>
          Configure Ruckus Interfaces
        </ListGroup.Item>
      </IsAllowed>
      <IsAllowed to="view:systemsettings:config">
        <ListGroup.Item tag="a" href="#5" action>
          System Configuration
        </ListGroup.Item>
      </IsAllowed>
    </ListGroup>
  </Card>
);

const SettingsPane = () => {
  const { userAccessToken, user } = useAuth();
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const refreshData = useSelector((state) => state.refreshdata);
  const [passedData, setPassedData] = useState({});

  const networksTableColumns = [
    {
      Header: "Building ",
      accessor: "building_name",
    },
    {
      Header: "Landlord",
      accessor: "landlord_name",
    },
    {
      Header: "Bandwidth Graph",
      accessor: "bandwidth_id",
      Cell: (e) =>
        e.value !== null ? (
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="ms-2"
            color="green"
          />
        ) : (
          <FontAwesomeIcon icon={faCircleXmark} className="ms-2" />
        ),
    },
    {
      Header: "Uptime Graph",
      accessor: "uptime_id",
      Cell: (e) =>
        e.value !== null ? (
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="ms-2"
            color="green"
          />
        ) : (
          <FontAwesomeIcon icon={faCircleXmark} className="ms-2" />
        ),
    },
    {
      Header: "Total BW",
      accessor: "total_bw",
    },
    {
      Header: "OSIA",
      accessor: "osia_bw",
    },
    {
      Header: "DIA",
      accessor: "dia_bw",
    },
    {
      Header: "Primary Circuit",
      accessor: "primary_circuit",
    },
    {
      Header: "Secondary Circuit",
      accessor: "secondary_circuit",
    },
    {
      Header: "Tertiary Circuit",
      accessor: "tertiary_circuit",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <>
          <IsAllowed to="edit:systemsettings:users">
            <FontAwesomeIcon
              icon={faEdit}
              className="ms-2"
              onClick={() => {
                toggle("editBuildingNetwork");
                passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          </IsAllowed>
        </>
      ),
    },
  ];

  const devicesTableColumns = [
    {
      Header: "LogicMonitor ID",
      accessor: "device_lm_id",
    },
    {
      Header: "Device Hostname",
      accessor: "device_hostname",
    },
    {
      Header: "Device IP",
      accessor: "device_ip",
    },
    {
      Header: "Friendly Name",
      accessor: "device_name",
    },
    {
      Header: "Building",
      accessor: "building_id",
    },
    {
      Header: "Landlord",
      accessor: "landlord_id",
    },
    {
      Header: "Building Code",
      accessor: "building_code",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <>
          <IsAllowed to="edit:systemsettings:users">
            <FontAwesomeIcon
              icon={faEdit}
              className="ms-2"
              onClick={() => {
                //toggle("configureDot11Credential");
                //passData(row.original);
              }}
              style={{ cursor: "pointer" }}
            />
          </IsAllowed>
        </>
      ),
    },
  ];

  const [operator, setOperator] = useState(selectedLandlord.landlordid);
  const [operatorDisabled, setOperatorDisabled] = useState(0);
  const [excellId, setExcellId] = useState("");

  // Load buildings & operators
  const [buildingsOperatorsList, setBuildingsOperatorsList] = useState([]);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.connectedApiUrl + "buildings/withoperator?page_size=1000",
        axiosConfig
      )
      .then((response) => {
        setBuildingsOperatorsList(response.data);
        setLoading(false);
        setNoData(false);
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }, [refreshData, bearerToken]);

  // Load building networks
  const [networksList, setNetworksList] = useState([]);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.monitoringApiUrl + "core/management/allnetworks",
        axiosConfig
      )
      .then((response) => {
        response.data.forEach((network, index, arr) => {
          if (
            buildingsOperatorsList.buildings &&
            buildingsOperatorsList.buildings.items &&
            buildingsOperatorsList.buildings.items.length > 0
          ) {
            let buildingMatch = buildingsOperatorsList.buildings.items.filter(
              (building) => {
                return building.id === network.building_id;
              }
            );
            arr[index].building_name = buildingMatch[0]?.building_name || null;
            arr[index].landlord_name =
              buildingMatch[0]?.operator.operator_name || null;
          }
        });
        setNetworksList(response.data);
        setLoading(false);
        setNoData(false);
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }, [refreshData, bearerToken, buildingsOperatorsList]);

  // Load building devices
  const [devicesList, setDevicesList] = useState([]);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.monitoringApiUrl + "core/management/alldevices",
        axiosConfig
      )
      .then((response) => {
        setDevicesList(response.data);
        //console.log(response.data);
        setLoading(false);
        setNoData(false);
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }, [refreshData, bearerToken]);

  // Operator Options for create new user modal dropdown
  const [operatorOptions, setOperatorOptions] = useState([]);
  React.useEffect(() => {
    // Modify to only return own Operator if role not super or wavenet
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.usersApiUrl + "permissions", axiosConfig)
      .then((response) => {
        if (response.data[user.roles].includes("view:operators:all")) {
          axios
            .get(
              apiConfig.connectedApiUrl + "operators?page_size=1000",
              axiosConfig
            )
            .then(function (response) {
              let operatorsList = [];
              response.data.operators.items.forEach((operator) => {
                operatorsList.push({
                  value: operator.id,
                  label:
                    operator.operator_name +
                    " (" +
                    operator.operator_code +
                    ")",
                });
              });
              //setLoading(false);
              setOperatorOptions(operatorsList);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
        } else {
          const operatorsList = [
            {
              value: selectedLandlord.landlordid,
              label: selectedLandlord.landlordname,
            },
          ];
          setOperatorOptions(operatorsList);
          setOperatorDisabled(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bearerToken, selectedLandlord, user]);
  // Customer Options for create new user modal dropdown
  const [customerOptions, setCustomerOptions] = useState([]);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.symbillApiUrl + "newsymbillapi/byCtidList/" + operator,
        axiosConfig
      )
      .then(function (response) {
        let customersList = [];
        response.data.forEach((customer) => {
          customersList.push({
            value: customer.accountNumber,
            label: customer.companyName + " (" + customer.accountNumber + ")",
          });
        });
        //setLoading(false);
        setCustomerOptions(customersList);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [operator, bearerToken]);
  // Role Options for create new u

  /* Building Options for network management UIs
  const [buildingOptions, setBuildingOptions] = useState([]);
  React.useEffect(() => {
    // Modify to only return own Operator if role not super or wavenet
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(apiConfig.bcsApiUrl + "/buildings?pageSize=1000", axiosConfig)
      .then(function (response) {
        let buildingsList = [];
        response.data.Buildings.items.forEach((building) => {
          buildingsList.push({
            value: building.id,
            label: building.building_name + " (" + building.id + ")",
          });
        });
        //setLoading(false);
        setBuildingOptions(buildingsList);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [bearerToken, selectedLandlord, user]); */

  const notyf = useContext(NotyfContext);

  const [type] = useState("success");
  const [duration] = useState("5000");
  const [ripple] = useState(true);
  const [dismissible] = useState(false);
  const [positionX] = useState("right");
  const [positionY] = useState("top");

  // Modal config for "Configure Wireless Network" Action
  const initOpenModals = () => {
    let modals = {};
    //console.log(modals);
    return modals;
  };

  const [openModals, setOpenModals] = useState(() => initOpenModals());
  const toggle = (index) => {
    // Toggle selected element
    setOpenModals((openModals) =>
      Object.assign({}, openModals, { [index]: !openModals[index] })
    );
  };

  const passData = (data) => {
    setPassedData(data);
  };

  // Handle edit building network
  const handleEditBuildingNetwork = (event) => {};

  const dispatch = useDispatch();

  const [userLoginsCount, setUserLoginsCount] = useState(0);
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(`${apiConfig.usersApiUrl}users/userlogins`, axiosConfig)
      .then((response) => setUserLoginsCount(response.data.count))
      .catch((err) => console.log(err));
  }, [bearerToken]);
  return (
    <>
      <Card>
        <Tab.Content>
          <Tab.Pane eventKey="#8">
            <IsAllowed
              to="view:systemsettings:config"
              fallback={<NotAuthorised />}
            >
              <Card.Header>
                <Card.Title className="mb-0">
                  Configure Ruckus Interfaces
                </Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">-- Interface list table --</Card.Body>
            </IsAllowed>
          </Tab.Pane>
          <Tab.Pane eventKey="#5">
            <IsAllowed
              to="view:systemsettings:config"
              fallback={<NotAuthorised />}
            >
              <Card.Header>
                <Card.Title className="mb-0">System Configuration</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <span>
                  Tenant Wifi Admin logins since migration: {userLoginsCount}
                </span>
              </Card.Body>
            </IsAllowed>
          </Tab.Pane>
          <UserManagement
            openModals={openModals}
            toggle={toggle}
            passedData={passedData}
            dispatch={dispatch}
            setRefreshData={setRefreshData}
            passData={passData}
            refreshData={refreshData}
          />
          <Dot11Admin />
          <Tab.Pane eventKey="#2">
            <IsAllowed
              to="view:systemsettings:roles"
              fallback={<NotAuthorised />}
            >
              <Card.Header>
                <Card.Title className="mb-0">
                  Roles &amp; Permissions
                </Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <Form>
                  <Row>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="inputSMS">
                          Control how you receive platform notifications with
                          the settings below.
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          id="inputSMS"
                          name="customSwitch"
                          label="SMS Notifications"
                          className="m-3"
                        />
                        <Form.Check
                          type="switch"
                          id="inputEmail"
                          name="customSwitch"
                          label="Email Notifications"
                          className="m-3"
                        />
                        <Form.Check
                          type="switch"
                          id="inputPush"
                          name="customSwitch"
                          label="Push Notifications"
                          className="m-3"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    onClick={() =>
                      notyf.open({
                        type,
                        message: "Your notifications settings have been saved.",
                        duration,
                        ripple,
                        dismissible,
                        position: {
                          x: positionX,
                          y: positionY,
                        },
                      })
                    }
                  >
                    Save changes
                  </Button>
                </Form>
              </Card.Body>
            </IsAllowed>
          </Tab.Pane>
          <Tab.Pane eventKey="#6">
            <IsAllowed to="edit:systemsettings" fallback={<NotAuthorised />}>
              <Card.Header>
                <Card.Title className="mb-0">Building Networks</Card.Title>
              </Card.Header>
              <Card.Body className="py-0">
                {loading && (
                  <>
                    <LogoLoader className="d-block m-auto p-4" />
                  </>
                )}
                {!loading && noData && <NotFound />}
                {!loading && !noData && (
                  <>
                    <p>Use the table below to configure building networks.</p>
                    <ColumnFilteringTable
                      columns={networksTableColumns}
                      data={networksList}
                      actions
                    />
                  </>
                )}
              </Card.Body>
            </IsAllowed>
          </Tab.Pane>
          <Tab.Pane eventKey="#7">
            <IsAllowed to="edit:systemsettings" fallback={<NotAuthorised />}>
              <Card.Header>
                <Card.Title className="mb-0">Building Devices</Card.Title>
              </Card.Header>
              <Card.Body className="py-0">
                {loading && (
                  <>
                    <LogoLoader className="d-block m-auto p-4" />
                  </>
                )}
                {!loading && noData && <NotFound />}
                {!loading && !noData && (
                  <>
                    <p>Use the table below to configure building devices.</p>
                    <ColumnFilteringTable
                      columns={devicesTableColumns}
                      data={devicesList}
                      actions
                    />
                  </>
                )}
              </Card.Body>
            </IsAllowed>
          </Tab.Pane>
          <Tab.Pane eventKey="#2">
            <IsAllowed
              to="view:systemsettings:roles"
              fallback={<NotAuthorised />}
            >
              <Card.Header>
                <Card.Title className="mb-0">
                  Roles &amp; Permissions
                </Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <Form>
                  <Row>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="inputSMS">
                          Control how you receive platform notifications with
                          the settings below.
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          id="inputSMS"
                          name="customSwitch"
                          label="SMS Notifications"
                          className="m-3"
                        />
                        <Form.Check
                          type="switch"
                          id="inputEmail"
                          name="customSwitch"
                          label="Email Notifications"
                          className="m-3"
                        />
                        <Form.Check
                          type="switch"
                          id="inputPush"
                          name="customSwitch"
                          label="Push Notifications"
                          className="m-3"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    onClick={() =>
                      notyf.open({
                        type,
                        message: "Your notifications settings have been saved.",
                        duration,
                        ripple,
                        dismissible,
                        position: {
                          x: positionX,
                          y: positionY,
                        },
                      })
                    }
                  >
                    Save changes
                  </Button>
                </Form>
              </Card.Body>
            </IsAllowed>
          </Tab.Pane>
          <Tab.Pane eventKey="#3">
            <IsAllowed
              to="view:systemsettings:buildings"
              fallback={<NotAuthorised />}
            >
              <Card.Header>
                <Card.Title className="mb-0">Buildings</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <Form>
                  <Row>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="inputSMS">
                          Control how you receive platform notifications with
                          the settings below.
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          id="inputSMS"
                          name="customSwitch"
                          label="SMS Notifications"
                          className="m-3"
                        />
                        <Form.Check
                          type="switch"
                          id="inputEmail"
                          name="customSwitch"
                          label="Email Notifications"
                          className="m-3"
                        />
                        <Form.Check
                          type="switch"
                          id="inputPush"
                          name="customSwitch"
                          label="Push Notifications"
                          className="m-3"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    onClick={() =>
                      notyf.open({
                        type,
                        message: "Your notifications settings have been saved.",
                        duration,
                        ripple,
                        dismissible,
                        position: {
                          x: positionX,
                          y: positionY,
                        },
                      })
                    }
                  >
                    Save changes
                  </Button>
                </Form>
              </Card.Body>
            </IsAllowed>
          </Tab.Pane>
          <Tab.Pane eventKey="#4">
            <IsAllowed
              to="view:systemsettings:landlords"
              fallback={<NotAuthorised />}
            >
              <Card.Header>
                <Card.Title className="mb-0">Landlords</Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <Form>
                  <Row>
                    <Col md="6">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="inputSMS">
                          Control how you receive platform notifications with
                          the settings below.
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          id="inputSMS"
                          name="customSwitch"
                          label="SMS Notifications"
                          className="m-3"
                        />
                        <Form.Check
                          type="switch"
                          id="inputEmail"
                          name="customSwitch"
                          label="Email Notifications"
                          className="m-3"
                        />
                        <Form.Check
                          type="switch"
                          id="inputPush"
                          name="customSwitch"
                          label="Push Notifications"
                          className="m-3"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    onClick={() =>
                      notyf.open({
                        type,
                        message: "Your notifications settings have been saved.",
                        duration,
                        ripple,
                        dismissible,
                        position: {
                          x: positionX,
                          y: positionY,
                        },
                      })
                    }
                  >
                    Save changes
                  </Button>
                </Form>
              </Card.Body>
            </IsAllowed>
          </Tab.Pane>
        </Tab.Content>
      </Card>
      {/* This is where the user settings modals were */}
      <Modal
        show={openModals["editBuildingNetwork"]}
        onHide={() => toggle("editBuildingNetwork")}
        centered
      >
        <Form onSubmit={handleEditBuildingNetwork}>
          <Modal.Header closeButton>
            <b>{passedData.building_name}:</b>&nbsp; Edit building network
          </Modal.Header>
          <Modal.Body className="m-3">
            <p className="text-left my-0">
              <>
                <Form.Group className="mb-3">
                  <Form.Label>1. Bandwidth Graph</Form.Label>
                  {passedData.bandwidth_id === null ? (
                    <>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    </>
                  ) : (
                    <>
                      <br />
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="ms-2"
                        color="green"
                      />{" "}
                      LogicMonitor Graph ID #{passedData.bandwidth_id}{" "}
                      <span className="text-sm">
                        <a href="#">(change)</a>
                      </span>
                    </>
                  )}
                </Form.Group>
                <hr />
                <Form.Group className="mb-3">
                  <Form.Label>2. Uptime Graph</Form.Label>
                  {passedData.uptime_id === null ? (
                    <>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    </>
                  ) : (
                    <>
                      <br />
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="ms-2"
                        color="green"
                      />{" "}
                      LogicMonitor Graph ID #{passedData.uptime_id}{" "}
                      <span className="text-sm">
                        <a href="#">(change)</a>
                      </span>
                    </>
                  )}
                </Form.Group>
                <hr />
                <Form.Group className="mb-3">
                  <Form.Label>3. Bandwidth Allocations</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={operatorOptions}
                    onChange={(e) => setOperator(e.value)}
                    isDisabled={operatorDisabled}
                    defaultValue={operatorOptions
                      .filter(
                        (option) => option.value === selectedLandlord.landlordid
                      )
                      .shift()}
                  />
                  {operatorDisabled === 1 ? (
                    <p className="text-sm pt-2">
                      This user will be assigned to the{" "}
                      <b>{selectedLandlord.landlordname}</b> tenant.
                    </p>
                  ) : (
                    <p className="text-sm pt-2">
                      Select the building operator this user should be assigned
                      to.
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>4. Circuit References</Form.Label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={customerOptions}
                    onChange={(e) => setExcellId(e.value)}
                    value={
                      excellId
                        ? customerOptions.find(
                            (option) => option.value === excellId
                          )
                        : null
                    }
                  />
                  <p className="text-sm pt-2">
                    Select the customer or organisation this user should be
                    assigned to.
                  </p>
                </Form.Group>
              </>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => toggle("editBuildingNetwork")}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="info"
              onClick={() => {
                toggle("editBuildingNetwork");
                setTimeout(() => {
                  dispatch(setRefreshData());
                }, 2000);
              }}
            >
              Update Building Network
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const SystemSettings = () => (
  <React.Fragment>
    <Helmet title="System Configuration" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">
        System Configuration
        <OffcanvasHelp
          id="systemsettings"
          name="System Settings"
          scroll
          backdrop
        />
      </h1>
      <Tab.Container id="edit-profile-tabs" defaultActiveKey="#1">
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            <SettingsPane />
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  </React.Fragment>
);

export default SystemSettings;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, Row } from "react-bootstrap";
import { ReactComponent as LogoLoader } from "../../../assets/img/wavenet-animated-loader3.svg";

import { LifeBuoy, CheckCircle, ChevronsRight, Clock } from "react-feather";

import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import { apiConfig } from "../../../config";

import NotFound from "../../../components/NotFound";

import { useNavigate } from "react-router-dom";
import { setSearchFilters } from "../../../redux/slices/filters";

const TicketStats = () => {
  const { userAccessToken } = useAuth();
  const [ticketStats, setTicketStats] = useState([]);
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const selectedLandlord = useSelector((state) => state.landlordswitcher);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };
    axios
      .get(
        apiConfig.ticketsApiUrl +
          "tickets/ticketstats?operatorid=" +
          selectedLandlord.landlordid,
        axiosConfig
      )
      .then(function (response) {
        setLoading(false);
        setTicketStats(response.data[0]);
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }, [bearerToken, selectedLandlord]);
  return (
    <Row
      className="flex-row px-0 mx-0"
      onClick={() => navigate("/tickets/list")}
    >
      {loading && <LogoLoader className="d-block m-auto p-4" />}
      {!loading && noData && <NotFound />}
      {!loading && !noData && (
        <>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">{ticketStats?.total || 0}</h3>
                    <p className="mb-2">Total Number Of Tickets</p>
                    <div className="mb-0">
                      {/*<Badge bg="" className="badge-soft-success me-2">
                        -8.65%
                      </Badge>*/}
                      <span className="text-muted text-sm">
                        in the last 30d
                      </span>
                    </div>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <LifeBuoy className="align-middle text-success" />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">{ticketStats?.resolved || 0}</h3>
                    <p className="mb-2">Resolved Tickets</p>
                    <div className="mb-0">
                      {/*<Badge bg="" className="badge-soft-success me-2">
                        96.5%
                      </Badge>*/}
                      <span className="text-muted text-sm">
                        in the last 30d
                      </span>
                    </div>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <CheckCircle className="align-middle text-success" />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body className=" py-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">{ticketStats?.open || 0}</h3>
                    <p className="mb-2">Open Tickets</p>
                    <div className="mb-0">
                      {/*<Badge bg="" className="badge-soft-warning me-2">
                        +5.35%
                      </Badge>*/}
                      <span className="text-muted text-sm">
                        created within the last 30d
                      </span>
                    </div>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <ChevronsRight className="align-middle text-success" />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl className="d-flex">
            <Card className="flex-fill">
              <Card.Body
                className=" py-4"
                onClick={() =>
                  dispatch(
                    setSearchFilters({
                      prolonged: true,
                    })
                  )
                }
              >
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h3 className="mb-2">{ticketStats?.prolonged || 0}</h3>
                    <p className="mb-2">Prolonged Tickets</p>
                    <div className="mb-0">
                      {/*<Badge bg="" className="badge-soft-success me-2">
                        8.35%
                      </Badge>*/}
                      <span className="text-muted text-sm">
                        created more than 30d ago
                      </span>
                    </div>
                  </div>
                  <div className="d-inline-block ms-3">
                    <div className="stat">
                      <Clock className="align-middle text-success" />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};

export default TicketStats;

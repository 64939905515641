import { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";

function ScrollToAnchor() {
  const location = useLocation();
  const observer = useRef(null);

  const scrollToTargetWithRetry = useCallback((targetId, attempts = 3) => {
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      // If the element is found, scroll to it and disconnect the observer
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      observer.current?.disconnect();
    } else if (attempts > 0) {
      // If not found and there are attempts left, wait a bit and try again
      setTimeout(() => scrollToTargetWithRetry(targetId, attempts - 1), 100);
    }
  }, []);

  useEffect(() => {
    const targetId = location.hash.slice(1);

    // Immediately try to scroll to the target element with retries
    scrollToTargetWithRetry(targetId);

    // Set up a MutationObserver to observe for when the element is added to the DOM
    const config = { attributes: true, childList: true, subtree: true };
    observer.current = new MutationObserver(() => {
      scrollToTargetWithRetry(targetId);
    });

    observer.current.observe(document.body, config);

    // Cleanup function to disconnect the observer on component unmount
    return () => observer.current?.disconnect();
  }, [location, scrollToTargetWithRetry]);

  return null;
}

export default ScrollToAnchor;

import React from "react";

import moment from "moment";
import { Badge, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const statusColourMap = {
  "Customer Responded": "danger",
  "Customer Responded*": "danger",
  "In Progress": "info",
  "On-Hold": "info",
  "Waiting on Customer": "info",
  "Customer Chase Sent*": "success",
  Responded: "success",
  Resolved: "dark",
  "Resolved*": "dark",
};
const statusStyleMap = {
  "Customer Responded": "",
  "Customer Responded*": "",
  "In Progress": "",
  "On-Hold": "",
  "Waiting on Customer": "",
  "Customer Chase Sent*": "",
  Responded: "",
  Resolved: "",
  "Resolved*": "",
};

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Form.Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  );
}

const tableColumns = [
  {
    Header: "Reference",
    accessor: "TicketID",
    Cell: (e) => (
      <div className="text-center">
        <Link to={"/tickets/view/" + e.value}>
          <Button variant="outline-primary">{e.value}</Button>
        </Link>
      </div>
    ),
  },
  {
    Header: "Description",
    accessor: "TicketSummary",
  },
  {
    Header: "Customer",
    accessor: "CustomerName",
  },
  {
    Header: "Requester",
    accessor: "Requester",
  },
  {
    Header: "Open since",
    accessor: "OpenSince",
    Cell: (e) => moment(e.value).local().format("DD-MM-YYYY HH:mm"),
  },
  {
    Header: "Status",
    accessor: "TicketStatus",
    Filter: SelectColumnFilter,
    filter: "includes",
    Cell: (e) => (
      <Badge bg={statusColourMap[e.value]} className={statusStyleMap[e.value]}>
        {e.value}
      </Badge>
    ),
  },
  {
    Header: "Account Number",
    accessor: "CustomerAccountNumber",
  },
];

export { tableColumns };

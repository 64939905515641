import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import axios from "axios";

import Marker from "./Marker";
import useAuth from "../../hooks/useAuth";
import { apiConfig } from "../../config";
import { ReactComponent as LogoLoader } from "../../assets/img/wavenet-animated-loader3.svg";

const DEFAULT_LOCATION = [51.492223002316244, -0.11991308593171082];

const Map = ({ location, address, name }) => {
  const { bid } = useParams();
  const { userAccessToken } = useAuth();
  const [locationData, setLocationData] = useState(DEFAULT_LOCATION);
  const bearerToken = `Bearer ${userAccessToken}`;
  const [loading, setLoading] = useState(true);

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: false,
      scrollwheel: false,
      streetViewControl: true,
    };
  };

  useEffect(() => {
    const axiosConfig = {
      headers: { Authorization: bearerToken },
    };

    axios
      .get(`${apiConfig.connectedApiUrl}buildings/${bid}`, axiosConfig)
      .then((response) => {
        setLoading(false);
        const coordinates = response.data.building.coordinate.coordinates;
        if (coordinates && coordinates.length) {
          setLocationData(coordinates);
        } else {
          setLocationData(DEFAULT_LOCATION);
        }
      })
      .catch((error) => console.log(error));
  }, [bearerToken, bid]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>Location</Card.Title>
        <h5 className="card-subtitle text-muted mb-0">{address}</h5>
      </Card.Header>
      <Card.Body className="pt-0">
        <div style={{ height: 300, width: "100%" }}>
          <GoogleMapReact
            options={getMapOptions}
            bootstrapURLKeys={{
              key: "AIzaSyD9I__gvEX0ScDy654-RJ9SOlt2sqeJGb4",
            }}
            defaultCenter={{
              lat: DEFAULT_LOCATION[0],
              lng: DEFAULT_LOCATION[1],
            }}
            defaultZoom={10}
          >
            {loading && <LogoLoader className="d-block m-auto p-4" />}
            {!loading && (
              <Marker lat={locationData[0]} lng={locationData[1]} name={name} />
            )}
          </GoogleMapReact>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Map;

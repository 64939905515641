import React from "react";
import usePermissions from "../hooks/usePermissions";

const IsAllowed = ({ to, fallback, loadingComponent, children }) => {
  const [loading, allowed] = usePermissions(to);

  if (loading) {
    return <>{loadingComponent}</>;
  }

  if (allowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

export default IsAllowed;

import React from "react";

import { Badge } from "react-bootstrap";

import { quantum } from "ldrs";

quantum.register();

const WorkflowStatusBadge = ({ status }) => {
  const statusStyleMap = {
    completed: "success",
    failed: "danger",
    running: "info",
  };

  return (
    <Badge bg={statusStyleMap[status]}>
      {status === "running" ? <l-quantum size="12" color="white" /> : ""}
      {status}
    </Badge>
  );
};

export default WorkflowStatusBadge;
